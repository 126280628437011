<template>
	<div :class="{'wfrmPageHide':ucPageHide}">
		<div class="wfrmT">
			<div class="wfrmLoc fl">
			   <div class="fl flLoc"  v-if="menuname!== undefined && menuname.length >=1"><span>{{ menuname }}</span> / <span>{{ submenuname }}</span></div>
			   <div class="fl wfrmIconQueDiv" v-if="description!== undefined && description.length >=1">
				   <div class="wfrmIconQue linear"></div>
				   <div class="wfrmIconQueShow">
					   <i class="iArr"></i>
					   <div class="wfrmIconQueShowM">{{ description }}</div>
				   </div>
			   </div>
			   <div class="clear"></div>
			</div>

			<div class="wfrmTr">

				<!-- <div class="wfrmTmenu" id="testh">
					<div class="wfrmTmenuIcon iconHome curP" v-on:click="clickHome"></div>
				</div> -->


				<!-- <div class="wfrmTmenu">
					<div class="wfrmTmenuIcon iconList"></div>
					<div class="wfrmTmenuArr"></div>
					<div class="wfrmTmenuShow linear">
						<i class="iArr"></i>
						<ul>
							<li v-for="link in links" v-bind:key="link.name" v-on:click="clickLink(link)">{{link.name}}
							</li>
						</ul>
					</div>
				</div> -->
				<!-- <div class="wfrmTmenu">
					<div class="wfrmTmenuIcon iconSet curP" v-on:click="clickSetting"></div>
				</div> -->
				<div class="wfrmTmenu wfrmTmenuAd">
					<div class="wfrmTmenuIcon"><i class="iIcon"></i></div>
					<div class="wfrmTmenuName">Hi,<span>{{username}}</span></div>
				</div>
				<div class="wfrmTmenu curP" v-on:click="editPsw">
					<div class="wfrmTmenuIcon iconPassword" ></div>
					<div class="wfrmTmenuName">变更密码</div>
				</div>
				<div class="wfrmTmenu curP" v-on:click="logout">
					<div class="wfrmTmenuIcon iconQuit" ></div>
					<div class="wfrmTmenuName">退出</div>
				</div>
			</div>

		</div>
		<div class="wfrmL">
			<div class="wfrmName">
				<!-- <img src="../components/imgs/ctms/logo.png"/> -->
				星管家智能化管理系统
			</div><!-- {{ systemname }} -->
			<wfrm_menu_tree v-bind:menus="menus" v-on:click-submenu="clickSubMenu"></wfrm_menu_tree>
		</div>

		<div class="wfrmR">
			
			
			<div class="wfrmRmConView">
				<router-view :key="key" />
			</div>
			
		</div>


	<div class="wfrmPageArr" v-on:click="changeUcPageArr"></div>
	
	<div v-if="showLogin" class="wfrmLoginFullbg">
		<wfrm_login v-bind:wfrmopts="wfrmopts" msg='' />
	</div>
	<!-- <div v-if="showReg" class="wfrmRegBg wfrmLoginFullbg">
		<wfrm_reg  v-bind:wfrmopts="wfrmopts" />
	</div>
	<div v-if="showForgetPass" class="wfrmRegBg wfrmLoginFullbg">
		<wfrm_forget_pass  v-bind:wfrmopts="wfrmopts" />
	</div> -->
	
	<psw_info @closepswinfo="closepswinfo"  v-if="centerDialogVisible"></psw_info>
	</div>

</template>

<script>
	// import wfrm_menu_tree from '../components/wfrm_menu_tree.vue'
	// import wfrm_login from '../components/wfrm_login.vue'
	//import wfrm_reg from '../components/wfrm_reg.vue'
	//import wfrm_forget_pass from '../components/wfrm_forget_pass.vue'
	//import {fetchRouterList} from '../wrfapi'
	import axios from 'axios';
	import psw_info from './psw_info.vue'
	import { ElNotification } from 'element-plus'
	export default {
		name: 'wfrm_frame',
		props: ["wfrmopts"],
		data: function() {
			return {
				links: this.wfrmopts.widget.links.links,
				username: '',
				uid:0,
				menus: [],
				menuname: "",
				submenuname: "",
				systemname: "",
				showLogin: true,
				showReg:false,
				showForgetPass:false,
				appContext: null,
				ucPageHide: false,
				description:"",
				centerDialogVisible: false,
				autoplay: true
			}
		},
		created() {
		},
		mounted() {
		  //判断当前登录状态
		  this.loadData();
		},
		computed: {
			key() {
				return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
			}
		},
		methods: {
			getTotelNumber() {
				var redata = {};
				redata.cmd = "getPlotNameByIsRead";
				redata.start = 0;
				redata.count = 1;
				this.$sknet.Config.maskFlag = false;
				this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
					this.$sknet.Config.maskFlag = true;
					for(var i=0;i<datas.datas.items.length;i++){
						this.openNotify(datas.datas.items[i].real_name + "提交了一条报修，内容:"+datas.datas.items[i].manage,datas.datas.items[i].id,"项目："+datas.datas.items[i].plot_name);
					}
				}, function(data) {
					this.$sknet.Config.maskFlag = true;
				}, this);
			},
			updateRmsOrderRecor(id) {
				var redata = {};
				redata.cmd = "updatePlotNameByIsRead";
				redata.id = id;
				this.$sknet.Config.maskFlag = false;
				this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
					this.$sknet.Config.maskFlag = true;
				}, function(data) {
					this.$sknet.Config.maskFlag = true;
				}, this);
			},
			openNotify(message,id,title) {
				 let self = this;
				 let notify = ElNotification({
					_id:id,
					duration: 0,// 不自动关闭
					title: title,
					message: message,
					showClose:true,
					position: 'bottom-right',
					onClose(data){
						console.log("close:"+data.props._id);
						//self.updateRmsOrderRecor(data.props._id);
					},
					onClick(data){
						console.log("click:"+data.currentTarget.outerHTML);
					},
				})
				var url = "https://fanyi.sogou.com/reventondc/synthesis?text="+message+"&speed=1&lang=zh-CHS&from=translateweb&speaker=6";
				url = "https://tts.youdao.com/fanyivoice?word="+message+"&le=zh&keyfrom=speaker-target";
				var audio = new Audio(url);
				self.autoplay = true;
				audio.play().then(() => {
					self.autoplay = true;
				}).catch(err => {
					console.log("err:" + err);
					self.autoplay = false;
				}).finally(() => {
					self.autoplay = false;
					audio.remove();
					self.updateRmsOrderRecor(id);
				});
			
			 },
			changeUcPageArr: function() {
				if (this.ucPageHide) {
					this.ucPageHide = false;
				} else {
					this.ucPageHide = true;
				}
			},
			clickSubMenu: function(menu, subMenu) {
				for (var i = 0; i < this.menus.length; i++) {
					for (var j = 0; j < this.menus[i].subMenus.length; j++) {
						if (this.menus[i].subMenus[j].id == subMenu.id && this.menus[i].id == menu.id) {
							this.menus[i].subMenus[j].selected = true;
						} else {
							this.menus[i].subMenus[j].selected = false;
						}
					}
				}
				
				
				// var params = subMenu.params;
				
				window.location.hash = "#/" + subMenu.src;
				
				// params = JSON.parse(params);
				// if(params == null || params == '' || params.length == 0){
				// 	window.location.hash = "#/" + subMenu.src;
				// }else{
				// 	var patn_params = "?";
				// 	for(var u in params){
				// 		if(u == 0){
				// 			patn_params = patn_params+params[u].name+"="+params[u].value;
				// 		}else{
				// 			patn_params = patn_params + "&" +params[u].name+"="+params[u].value;
				// 		}
				// 	}
				// 	window.location.hash = "#/" + subMenu.src+patn_params;
				// }
				this.menuname = menu.name;
				this.submenuname = subMenu.name;
				this.description = subMenu.description;
				
			},
			loadData() {
				var self = this;
				this.$sknet.postJson(this.wfrmopts.auth.authUrl + 'checkUserLogin', {}, function(res) {
					self.username = res.name;
					self.uid = res.uid;
					self.getmenus();
					self.showLogin = false;
					
					//获取新订单
					self.getTotelNumber();
					//self.$parent.popTimeline(false);
				}, function() {
					self.showLogin = true;
					//self.$parent.popTimeline(true);
				}, null);
			},
			getnameByurl: function() {
				var nowmenu = {};
				//获取url中的hash
				var path = this.$route.path
				if (path == null || path == "" || path == "/") {
					return;
				} else {
					path = path.substr(1);
				}
				for (var i = 0; i < this.menus.length; i++) {
					for (var j = 0; j < this.menus[i].subMenus.length; j++) {
						if (this.menus[i].subMenus[j].src == path) {
							nowmenu = this.menus[i].subMenus[j];
							this.menuname = this.menus[i].name;
							this.submenuname = this.menus[i].subMenus[j].name;
							this.description = this.menus[i].subMenus[j].description;
							this.menus[i].open = true;
							this.menus[i].subMenus[j].selected = true;
						} else {
							this.menus[i].subMenus[j].selected = false;
						}
					}
				}
				window.location.hash = "#/";
				var params = nowmenu.params;
				
				if(params == null || params == '' || params.length == 0){
					window.location.hash = "#/" + path;
				}else{
					params = JSON.parse(params);
					var patn_params = "?";
					for(var u in params){
						if(u == 0){
							patn_params = patn_params+params[u].name+"="+params[u].value;
						}else{
							patn_params = patn_params + "&" +params[u].name+"="+params[u].value;
						}
					}
					window.location.hash = "#/" + path+patn_params;
				}
			},
			setUserName: function(username) {
				this.username = username;
			},
			getmenus: function() {
				var self = this;
				this.$sknet.postJson(this.wfrmopts.pdl.urlMenus, {}, function(datas) {
					self.systemname = "报维修管理系统";
					var menus = datas.datas.menus; // br-pd 0.0.7用这个
					var routermenu = [];
					for (var i = 0; i < menus.length; i++) {
						menus[i].open = false;
						if(menus[i].icon != undefined && menus[i].icon != null && 
								menus[i].icon.length == 8){
							menus[i].icon = self.$skconf("frm.oss.vurl") + menus[i].icon;
						}else{
							menus[i].icon = menus[i].icon;
						}
						
						for (var j = 0; j < menus[i].subMenus.length; j++) {
							menus[i].subMenus[j].selected = false;
							menus[i].subMenus[j].src = menus[i].subMenus[j].src;//br-pd 0.0.7用这个
							//menus[i].subMenus[j].src = menus[i].subMenus[j].path;
							routermenu.push(menus[i].subMenus[j]);
							var aaa = menus[i].subMenus[j];
							var comp = self.$.appContext.components[aaa.src];
							if (typeof comp === "undefined") {
								console.warn("loss component: " + aaa.src);
							} else {
								//self.$router.matcher.addRoute({
								self.$router.addRoute({
									path: "/" + aaa.src,
									name: aaa.src,
									component: comp
								});
							}
						}
					}
				
					//绩效配置
					var sec_performance_list = self.$.appContext.components['sec_performance_list'];
					self.$router.addRoute({
						path: "/sec_performance_list",
						name: "sec_performance_list",
						component: sec_performance_list
					});
					var rms_evaluate_infolist = self.$.appContext.components['rms_evaluate_infolist'];
					self.$router.addRoute({
						path: "/rms_evaluate_infolist",
						name: "rms_evaluate_infolist",
						component: rms_evaluate_infolist
					});
										//添加新的
					//let temp = fetchRouterList (routermenu);
					self.menus = menus;
					//self.$router.matcher.addRoutes(temp);
				
					self.getnameByurl();
				}, null, self);
				
				/**
					var menuJson = "";
					var uids = [63, 67]
					if( uids.indexOf(self.uid) != -1){
						menuJson = "/menu_temp.json";
					}else{
						menuJson = "/menu_temp2.json";
					}
					
					axios.get(menuJson).then(res => {
						var datas = res.data;
						self.systemname = "报维修管理系统";
						//var menus = datas.datas.menus; // br-pd 0.0.7用这个
						var menus = datas.menus;
						var routermenu = [];
						for (var i = 0; i < menus.length; i++) {
							menus[i].open = false;
							if(menus[i].icon != undefined && menus[i].icon != null && 
									menus[i].icon.length == 8){
								menus[i].icon = self.$skconf("frm.oss.vurl") + menus[i].icon;
							}else{
								menus[i].icon = menus[i].icon;
							}
							
							for (var j = 0; j < menus[i].subMenus.length; j++) {
								menus[i].subMenus[j].selected = false;
								//menus[i].subMenus[j].src = menus[i].subMenus[j].src;//br-pd 0.0.7用这个
								menus[i].subMenus[j].src = menus[i].subMenus[j].path;
								routermenu.push(menus[i].subMenus[j]);
								var aaa = menus[i].subMenus[j];
								var comp = self.$.appContext.components[aaa.src];
								if (typeof comp === "undefined") {
									console.warn("loss component: " + aaa.src);
								} else {
									//self.$router.matcher.addRoute({
									self.$router.addRoute({
										path: "/" + aaa.src,
										name: aaa.src,
										component: comp
									});
								}
							}
						}

						//绩效配置
						var sec_performance_list = self.$.appContext.components['sec_performance_list'];
						self.$router.addRoute({
							path: "/sec_performance_list",
							name: "sec_performance_list",
							component: sec_performance_list
						});
						var rms_evaluate_infolist = self.$.appContext.components['rms_evaluate_infolist'];
						self.$router.addRoute({
							path: "/rms_evaluate_infolist",
							name: "rms_evaluate_infolist",
							component: rms_evaluate_infolist
						});
											//添加新的
						//let temp = fetchRouterList (routermenu);
						self.menus = menus;
						//self.$router.matcher.addRoutes(temp);

						self.getnameByurl();
					}, null, self);
				**/
			},

			logout: function() {
				//清除Token
				localStorage.removeItem(this.$sknet.Config.saveToken + ".access");
				localStorage.removeItem(this.$sknet.Config.saveToken + ".update");
				
				this.$router.replace({
					path: '/'
				});
				location.reload();
			},
			clickHome: function() {
				window.open(this.wfrmopts.widget.home.url);
			},
			clickLink: function(link) {
				window.open(link.url);
			},
			clickSetting: function() {

			},
			editPsw: function() {
				this.centerDialogVisible = true;
			},
			closepswinfo: function() {
				this.centerDialogVisible = false;
			}
		},
		components: {
			//wfrm_reg,
			//wfrm_forget_pass,
			// wfrm_menu_tree,
			// wfrm_login
			psw_info
		},
		route: {
			data() {
				this.$root.showLoading();
				return this.fetchCertificates().then((res) => {
					this.$root.dismissLoading();
					if (res.error) return this.$root.toastError(res.error);
					if (res.data.certificates.length > 0) {
						return res.data;
					} else {
						console.log(this.$route, "----当前页面的url信息----");
					}
				});
			}
		}
	}
</script>
<style>
	.wfrmR {
		background-color: #d6dae6;
	}

	.wfrmT {
		width: auto;
		height: 58px;
		margin: 0px;
		background-color: #364860;
	}

	.wfrmName {
		width: 208px;
		height: 58px;
		float: left;
		line-height: 58px;
		font-size: 18px;
		padding-left: 12px;
		color: #fff;
		background-color: #2a3950;
		letter-spacing: 1px;
		line-height: 99px;
	}

	.wfrmTr {
		width: auto;
		height: auto;
		float: right;
	}

	.wfrmTmenu {
		width: auto;
		height: auto;
		float: left;
		margin-right: 20px;
		position: relative;
	}

	.wfrmTmenu .wfrmTmenuIcon {
		width: 30px;
		height: 58px;
		float: left;
		background-position: center;
	}

	.wfrmTmenu .wfrmTmenuIcon.iconHome {
		background-image: url(./imgs/menu_icon_home.png);
	}

	.wfrmTmenu .wfrmTmenuIcon.iconList {
		background-image: url(./imgs/menu_icon_list.png);
	}

	.wfrmTmenu .wfrmTmenuIcon.iconSet {
		background-image: url(./imgs/menu_icon_set.png);
	}

	.wfrmTmenu .wfrmTmenuIcon.iconQuit {
		background-image: url(./imgs/menu_icon_quit.png);
	}

	.wfrmTmenu .wfrmTmenuName {
		width: auto;
		height: 58px;
		float: left;
		line-height: 58px;
		color: #fff;
		padding-right: 5px;
	}

	.wfrmTmenu .wfrmTmenuArr {
		width: 14px;
		height: 58px;
		float: left;
		background-position: right center;
		opacity: 0.5;
		background-image: url(./imgs/wfrmMenu_arrD.png);
	}

	.wfrmTmenu.wfrmTmenuAd {
		padding-left: 50px;
	}

	.wfrmTmenu.wfrmTmenuAd .wfrmTmenuIcon {
		width: auto;
		margin-right: 5px;
	}

	.wfrmTmenu.wfrmTmenuAd .wfrmTmenuIcon i.iIcon {
		width: 36px;
		height: 36px;
		display: block;
		margin: 11px 0px 0px;
		border-radius: 50%;
		background-image: url(./imgs/adImg.png);
		background-size: 36px 36px;
		background-position: center;
	}

	.wfrmTmenuShow {
		width: auto;
		height: auto;
		position: absolute;
		top: 50px;
		right: -6px;
		z-index: 100;
		background-color: #fff;
		border-radius: 8px;
		padding: 12px 20px;
		box-shadow: 1px 2px 5px rgba(102, 114, 251, 0.2);
		display: none;
	}

	.wfrmTmenuShow ul li {
		width: auto;
		height: auto;
		display: block;
		margin: 0px;
		color: #61657e;
		cursor: pointer;
		line-height: 24px;
		white-space: nowrap;
	}

	.wfrmTmenuShow ul li:hover {
		color: #4b9cf8;
	}

	.wfrmTmenuShow i.iArr {
		width: 0;
		height: 0;
		display: block;
		position: absolute;
		right: 6px;
		top: -11px;
		z-index: 1;
		border-left: 6px transparent solid;
		border-right: 6px transparent solid;
		border-bottom: 6px #fff solid;
		border-top: 6px transparent solid;
	}

	.wfrmTmenu:hover .wfrmTmenuShow {
		display: block;
	}

	.wfrmL {
		width: 220px;
		height: auto;
		position: absolute;
		left: 0px;
		top: 58px;
		right: 0px;
		bottom: 0px;
		z-index: 1;
		overflow: hidden;
		overflow-y: auto;
		background-color: #364860;
		color: #e5e8ef;
	}

	.wfrmL::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}

	.wfrmL::-webkit-scrollbar-thumb {
		background-color: #2a3950;
	}

	.wfrmL::-webkit-scrollbar-thumb:hover {
		background-color: #17a2f7;
	}


	.wfrmR {
		width: auto;
		height: auto;
		position: absolute;
		left: 220px;
		top: 58px;
		right: 0px;
		bottom: 0px;
		z-index: 2;
		overflow: hidden;
		overflow-y: auto;
		
	}

	.wfrmLoc {
		width: auto;
		height: auto;
		margin: 0px 20px;
	}

	.wfrmLoc .flLoc {
		line-height: 50px;
		font-size: 16px;
		color: #6a7990;
		margin-right: 10px;
	}

	.wfrmIconQueDiv {
		width: 20px;
		height: 20px;
		position: relative;
		z-index: 20;
	}

	.wfrmIconQue {
		width: 20px;
		height: 20px;
		background-image: url(./imgs/iconQue.png);
		background-position: center;
		background-repeat: no-repeat;
		margin-top: 27px;
		cursor: help;
		opacity: 0.5;
	}

	.wfrmIconQueShow {
		width: auto;
		height: auto;
		position: absolute;
		left: 30px;
		top: 7px;
		z-index: 1;
		display: none;
	}

	.wfrmIconQueShow .iArr {
		width: 0;
		height: 0;
		position: absolute;
		left: -12px;
		top: 22px;
		z-index: 1;
		border-left: 6px transparent solid;
		border-right: 6px #464c59 solid;
		border-bottom: 6px transparent solid;
		border-top: 6px transparent solid;
	}

	.wfrmIconQueShowM {
		width: 170px;
		height: auto;
		margin: 0px;
		border-radius: 3px;
		background-color: #464c59;
		padding: 10px 15px;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
		color: #fff;
		line-height: 18px;
		font-size: 12px;
		min-height: 36px;
	}

	.wfrmIconQueShowM a.aBlue {
		margin-left: 5px;
		color: #4b9cf8;
	}

	.wfrmIconQueDiv:hover .wfrmIconQue {
		background-image: url(./imgs/iconQue_hover.png);
		opacity: 0.8;
	}

	.wfrmIconQueDiv:hover .wfrmIconQueShow {
		display: block;
	}

	.wfrmRmConView {
		width: auto;
		height: auto;
		margin: 0px;
		position: absolute;
		left: 0px;
		top: 50px;
		right: 0px;
		bottom: 0px;
		z-index: 10;
		overflow: hidden;
		overflow-y: auto;
		padding: 0px 20px;
	}

	.wfrmPageArr {
		width: 10px;
		height: 60px;
		position: fixed;
		left: 220px;
		top: 50%;
		margin-top: -30px;
		z-index: 100;
		background-image: url(./imgs/wfrmMenu_pageArrL.png);
		cursor: pointer;
	}

	.wfrmL,
	.wfrmR,
	.wfrmPageArr,
	.wfrmRmConView {
		-webkit-transition: all 0.3s linear;
		-moz-transition: all 0.3s linear;
		-o-transition: all 0.3s linear;
		-ms-transition: all 0.3s linear;
		transition: all 0.3s linear;
	}

	.wfrmPageHide .wfrmL {
		width: 0px;
		overflow: hidden;
	}

	.wfrmPageHide .wfrmR,
	.wfrmPageHide .wfrmRmConView {
		left: 0px;
	}

	.wfrmPageHide .wfrmPageArr {
		left: 0px;
		background-image: url(./imgs/wfrmMenu_pageArrR.png);
	}
</style>
