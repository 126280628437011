var Sk = {
	version : "2.0.0"
}
Sk.Utils = {};
Sk.Utils.valueOfObject = function(obj, key){
	if(obj && key){
		var fields = key.split(".");
		var el = obj;
		for(var i = 0; i < fields.length; i ++){
			el = el[fields[i]];
			if(typeof(el) == "undefined" || el == null){
				return el;
			}
		}
		
		return el;
	}
}
Sk.Net = {};

/**
 * 向url中添加参数
 */
Sk.Net._ap = function(url, key, value){
	var index = url.indexOf("?");
	if(index < 0){
		url += "?";
	}else{
		url += "&";
	}
	url += key
	url += "=";
	url += value;
	return url;
}

/**
 * 默认的成功函数
 */
Sk.Net._ds = function(data){
	alert(JSON.stringify(data));
};

/**
 * 默认的失败函数
 */
Sk.Net._de = function(data){
	alert("result is: "+data.result + ", status is: "+ data.status + ", reason is: " + data.reason);
};

/**
 * 使用xhr进行ajax
 */
Sk.Net._ajax = function(method, url, async, load, error, data){
	var xhr = new XMLHttpRequest();
	xhr.open(method,url,async);
	
	xhr.responseType = "json";
	xhr.withCredentials = true;
	
	//请求完成
	xhr.onload = function(){
		if(xhr.status == 200){
			var resData = xhr.response;
			if (typeof resData === 'string') {
				try {
					resData = JSON.parse(resData);
				} catch (e) { 
					console.warn("Sk.Net parser response error."); 
				}
		    }
			load(resData);
		}else{
			error(xhr);
		}
	}; 
	//请求失败
	xhr.onerror = function(){
		error(xhr);
	};
	//上传进度
	//xhr.upload.onprogress = function(){};
	
	if(!data){
		data = null;
	}
	xhr.send(data);
}

/**
 * ajax request
 * url:访问地址
 * data:需要Post的数据，Json形式
 * suc:成功的回调
 * err:失败的回调
 * owner：成功和失败回调时的域
 * te：Token过期的回调函数，对外不可见
 * tte: TenantToken过期得回调函数，对外不可见
 */
Sk.Net._req = function(url,data,async,maskFlag,suc,err,owner,te,tee){
	if(maskFlag){
		Sk.Net._om();
	}

	//将Json转换成String
	var postData;
	if(data instanceof FormData){
		postData = data;
	}else if(Object.prototype.toString.call(data) === '[object Object]'){
		postData = JSON.stringify(data);
	}else{
		postData = data;
	}
	
	//增加时间戳，防止浏览器缓存
	var index = url.indexOf("?");
	if(index < 0){
		url += "?";
	}else{
		url += "&";
	}
	url += "r=";
	url += new Date().getTime();
	
	Sk.Net._ajax("post", url, true, function(res){
		if(maskFlag){
			Sk.Net._cm();
		}
		
		var result = res.result||"";
		for(var i = 0; i < Sk.Net.Config.successResultTags.length; i ++){
			if(result == Sk.Net.Config.successResultTags[i]){
				Sk.Net._cbs(res, suc, owner);
				return;
			}
		}
		
		//如果是Token过期，
		if(Sk.Net.Config.useToken){
			var reason = res.reason || "";
			if(reason.substring(0,4) == "-205"){
				if(typeof(te) != "undefined"){
					te();
					return;
				}
			}
		}
		
		//如果是tenantToken过期
		if(Sk.Net.Config.useTenantToken){
			var reason = data.reason || "";
			if(reason.substring(0,4) == "-210"){
				if(tee === undefined){
				}else{
					tee();
					return;
				}
			}
		}
		
		res.status = 200;
		Sk.Net._cbe(res, err, owner);
	}, function(xhr){
		if(maskFlag){
			Sk.Net._cm();
		}
		var res = new Object();
		res.result = "NG";
		res.status = xhr.status;
		res.reason = "ajax error, status is " + xhr.status;
		Sk.Net._cbe(res, err, owner);
	}, postData);
};

/**
 * 刷新token
 */
Sk.Net._ut = function(suc, err){
	var postData = {};
	postData.cmd = "updateToken";
	postData.token = localStorage.getItem(Sk.Net.Config.saveToken+".update");
	Sk.Net._req(Sk.Net.Config.updateTokenUrl,postData,false,false, function(data){
		suc(data);
	}, function(){
		err();
	}, null);
};

/**
 * 刷新tenantToken
 */
Sk.Net._utt = function(suc, err){
	var postData = {};
	postData.tenant = localStorage.getItem(Sk.Net.Config.tenant);
	this._req(Sk.Net.Config.updateTenantTokenUrl,postData,false,false, function(data){
		suc(data);
	}, function(){
		err();
	}, null);
};

/**
 * 成功时回调函数
 */
Sk.Net._cbs = function(data, suc, owner){
	if(suc){
		if(owner === undefined || owner == null){
			suc(data);
		}else{
			suc.apply(owner,[data]);
		}
	}else{
		if(Sk.Net.Config.defaultSuccessFunction){
			if(owner === undefined || owner == null){
				Sk.Net.Config.defaultSuccessFunction(data);
			}else{
				Sk.Net.Config.defaultSuccessFunction.apply(owner,[data]);
			}
		}
	}
};

/**
 * 失败时回调函数
 */
Sk.Net._cbe = function(data, err, owner){
	if(err){
		if(owner === undefined || owner == null){
			err(data);
		}else{
			err.apply(owner,[data]);
		}
	}else{
		if(Sk.Net.Config.defaultErrorFunction){
			if(owner === undefined || owner == null){
				Sk.Net.Config.defaultErrorFunction(data);
			}else{
				Sk.Net.Config.defaultErrorFunction.apply(owner,[data]);
			}
		}
	}
};

/**
 * 开启loading页面
 */
Sk.Net._om = function(){
	var mask = document.getElementById("_sknetmask_");
	if(!mask){
		mask = document.createElement("div");
		mask.id = '_sknetmask_';
		mask.className = 'sknetmask';
		document.body.appendChild(mask);
	}
	mask.style.display="block";
};

/**
 * 关闭loading页面
 */
Sk.Net._cm = function(){
	var mask = document.getElementById("_sknetmask_");
	if(mask){
		mask.style.display="none";
	}
};

/**
 * 判断是否为数组
 */
Sk.Net._ia = function(o){
	return Object.prototype.toString.call(o) === '[object Array]';
};

/**
 * 取得要post的数据
 */
Sk.Net._gP = function(data){
	if(typeof(data) == "object"){
		return JSON.stringify(data);
	}else if(typeof(data) == "string"){
		return data;
	}else{
		return null;
	}
};

/**
 * 默认的配置项
 */
Sk.Net.Config = {
	maskFlag : false,
	successResultTags : ["OK"],
	defaultSuccessFunction : Sk.Net._ds,
	defaultErrorFunction : Sk.Net._de,
	useToken: false,
	passToken: "token",
	saveToken: "sk.token",
	updateTokenUrl: "",
	updateTokenFailMessage: "-108[no authenticated]",
	
	useTenantToken: false,
	tenant:"sk.tenant",
	passTenant: "tenantToken",
	saveTenant: "sk.tenantToken",
	updateTenantTokenUrl: "",
	updateTenantTokenFailMessage: "-108[no authenticated]"
};

/**
 * ajaxPost async request with json
 */
Sk.Net.postJson = function(url, data, suc, err, owner) {
	//如果使用Token机制，加入Token
	if(Sk.Net.Config.useToken){
		var token = localStorage.getItem(Sk.Net.Config.saveToken+".access");
		data[Sk.Net.Config.passToken] = token;
	}
	
	if(Sk.Net.Config.useTenantToken){
		var tenantToken = localStorage.getItem(Sk.Net.Config.saveTenant+".access");
		data[Sk.Net.Config.passTenant] = tenantToken;
	}
	
	return Sk.Net._req(url,data,true,Sk.Net.Config.maskFlag, suc, err, owner,
		function(){
			//更新Token
			Sk.Net._ut(function(utData){
				//存储Token，并重新调用
				localStorage.setItem(Sk.Net.Config.saveToken+".access", utData.token);
				localStorage.setItem(Sk.Net.Config.saveToken+".update", utData.updateToken);
				data[Sk.Net.Config.passToken] = utData.token;
				return Sk.Net._req(url,data,true,Sk.Net.Config.maskFlag, suc, err, owner);
			},function(){
				var data = {};
				data.result = "NG";
				data.reason = Sk.Net.Config.updateTokenFailMessage;
				Sk.Net._cbe(data, err, owner);
			});
		}, function(errorData){
			//更新TenantToken
			Sk.Net._utt(function(utData){
				//存储Token，并重新调用
				localStorage.setItem(Sk.Net.Config.saveTenant+".access", uttData.tenantToken);
				data[Sk.Net.Config.passTenant] = uttData.tenantToken;
				return Sk.Net._req(url,data,true,Sk.Net.Config.maskFlag, suc, err, owner);
			},function(){
				var data = {};
				data.result = "NG";
				data.reason = Sk.Net.Config.updateTenantTokenFailMessage;
				Sk.Net._cbe(data, err, owner);
			});
		}
		
	);
};

/**
 * ajaxPost sync request with json
  */
Sk.Net.postJsonSync = function(url, data, suc, err, owner) {
	//如果使用Token机制，加入Token
	if(Sk.Net.Config.useToken){
		var token = localStorage.getItem(Sk.Net.Config.saveToken+".access");
		data[Sk.Net.Config.passToken] = token;
	}
	
	if(Sk.Net.Config.useTenantToken){
		var tenantToken = localStorage.getItem(Sk.Net.Config.saveTenant+".access");
		data[Sk.Net.Config.passTenant] = tenantToken;
	}
	
	return Sk.Net._req(url,data,false,Sk.Net.Config.maskFlag, suc, err, owner,
		function(){
			//更新Token
			Sk.Net._ut(function(utData){
				//存储Token，并重新调用
				localStorage.setItem(Sk.Net.Config.saveToken+".access", utData.token);
				localStorage.setItem(Sk.Net.Config.saveToken+".update", utData.updateToken);
				data[Sk.Net.Config.passToken] = utData.token;
				return Sk.Net._req(url,data,false,Sk.Net.Config.maskFlag, suc, err, owner);
			},function(){
				var data = {};
				data.result = "NG";
				data.reason = Sk.Net.Config.updateTokenFailMessage;
				Sk.Net._cbe(data, err, owner);
			});
		}, function(errorData){
			//更新TenantToken
			Sk.Net._utt(function(utData){
				//存储Token，并重新调用
				localStorage.setItem(Sk.Net.Config.saveTenant+".access", uttData.tenantToken);
				data[Sk.Net.Config.passTenant] = uttData.tenantToken;
				return Sk.Net._req(url,data,true,Sk.Net.Config.maskFlag, suc, err, owner);
			},function(){
				var data = {};
				data.result = "NG";
				data.reason = Sk.Net.Config.updateTenantTokenFailMessage;
				Sk.Net._cbe(data, err, owner);
			});
		}
	);
};

/**
 * 上传文件
 */
Sk.Net.uploadFile = function(url, data, fileElements, suc, err,owner) {
	
	var fileElementIds = null;
	if(Sk.Net._ia(fileElements)){
		fileElementIds = fileElements;
	}else{
		fileElementIds = new Array();
		fileElementIds[0] = fileElements;
	}
	
	//制作formdata
	var form = new FormData();
	for(var i = 0; i < fileElementIds.length; i ++){
		var fileObj = document.getElementById(fileElementIds[i]).files[0];
		form.append(fileElementIds[i],fileObj);
	}
	
	//如果使用Token机制，加入Token
	if(Sk.Net.Config.useToken){
		var token = localStorage.getItem(Sk.Net.Config.saveToken+".access");
		data[Sk.Net.Config.passToken] = token;
	}
	
	if(Sk.Net.Config.useTenantToken){
		var tenantToken = localStorage.getItem(Sk.Net.Config.saveTenant+".access");
		data[Sk.Net.Config.passTenant] = tenantToken;
	}
	
	//添加json-data
	var postData = Sk.Net._gP(data);
	form.append("json-data", postData);
	
	return Sk.Net._req(url,form,true,Sk.Net.Config.maskFlag, suc, err, owner,
		function(){
			//更新Token
			Sk.Net._ut(function(utData){
				//存储Token，并重新调用
				localStorage.setItem(Sk.Net.Config.saveToken+".access", utData.token);
				localStorage.setItem(Sk.Net.Config.saveToken+".update", utData.updateToken);
				data[Sk.Net.Config.passToken] = utData.token;
				return Sk.Net._req(url,form,true,Sk.Net.Config.maskFlag, suc, err, owner);
			},function(){
				var data = {};
				data.result = "NG";
				data.reason = Sk.Net.Config.updateTokenFailMessage;
				Sk.Net._cbe(data, err, owner);
			}
		);
	});
};

/**
 * OSS上传文件
 */
Sk.Net.ossUploadFile = function(url, bucketId, fileElements, suc, err, owner){
	//如果使用Token机制，加入Token
	if(Sk.Net.Config.useToken){
		var token = localStorage.getItem(Sk.Net.Config.saveToken+".access");
		url = Sk.Net._ap(url, Sk.Net.Config.passToken, token);
	}
	
	if(Sk.Net.Config.useTenantToken){
		var tenantToken = localStorage.getItem(Sk.Net.Config.saveTenant+".access");
		url = Sk.Net._ap(url, Sk.Net.Config.passTenant, tenantToken);
	}
	
	url = Sk.Net._ap(url, "bucket", bucketId);
	
	var fileElementIds = null;
	if(Sk.Net._ia(fileElements)){
		fileElementIds = fileElements;
	}else{
		fileElementIds = new Array();
		fileElementIds[0] = fileElements;
	}
	
	var form = new FormData();
	for(var i = 0; i < fileElementIds.length; i ++){
		var fileObj = document.getElementById(fileElementIds[i]).files[0];
		form.append(fileElementIds[i],fileObj);
	}
	
	return Sk.Net._req(url, form, true, Sk.Net.Config.maskFlag, suc, err, owner,
		function(){
			//更新Token
			Sk.Net._ut(function(utData){
				//存储Token，并重新调用
				localStorage.setItem(Sk.Net.Config.saveToken+".access", utData.token);
				localStorage.setItem(Sk.Net.Config.saveToken+".update", utData.updateToken);
				//data[Sk.Net.Config.passToken] = utData.token;
				return Sk.Net._requf(url,fileElements,suc, err, owner);
			},function(){
				var data = {};
				data.result = "NG";
				data.reason = Sk.Net.Config.updateTokenFailMessage;
				Sk.Net._cbe(data, err, owner);
			}
		);
	});
}

/**
 * 把一个Object制作成请求参数
 */
Sk.Net.makeQueryString = function(obj) {	   
	var props = "";	  
	for(var p in obj){   
		var type = typeof(obj[p]);
		if(type != "function" && type != "object"){	   
			props += p;
			props += "=";
			//props += encodeURIComponent(obj[p]);
			props += obj[p];
			props += "&";
		}
	}
	
	if(props.length > 1){
		props = props.substring(0,props.length-1);
		//props = encodeURIComponent(props);
		props = encodeURI(props);
		return props;
	}
	
	return "";   
};

/**
 * 文件下载
 */
Sk.Net.download = function(url,data){
	var param = Sk.Net.makeQueryString(data);
	url = url+"?"+param;
	
	//如果使用Token机制，加入Token
	if(Sk.Net.Config.useToken){
		var token = localStorage.getItem(Sk.Net.Config.saveToken+".access");
		url = Sk.Net._ap(url, Sk.Net.Config.passToken, token);
	}
	
	if(Sk.Net.Config.useTenantToken){
		var tenantToken = localStorage.getItem(Sk.Net.Config.saveTenant+".access");
		url = Sk.Net._ap(url, Sk.Net.Config.passTenant, tenantToken);
	}
	
	window.location = url;
};
Sk.Config = function(key){
	throw new Error("value of :" + key + " didn't implemented");
};
Sk.UI = {};
/**
 * 显示对话框（指定DOM元素）
 */
//eslint-disable-next-line
Sk.UI.showDlg = function(dom, opts){
	throw new Error("Sk.UI didn't implemented.");
};

/**
 * 显示对话框（指定组件）
 */
//eslint-disable-next-line
Sk.UI.showDlgC = function(component, params, opts){
	throw new Error("Sk.UI didn't implemented.");
};

/**
 * 关闭对话框
 */
//eslint-disable-next-line
Sk.UI.closeDlg = function(id){
	throw new Error("Sk.UI didn't implemented.");
};

/**
 * 确认对话框
 */
//eslint-disable-next-line
Sk.UI.confirm = function(title, message, opts){
	throw new Error("Sk.UI didn't implemented.");
};

/**
 * 可自动关闭的提示
 */
//eslint-disable-next-line
Sk.UI.toast = function(message, opts){
	throw new Error("Sk.UI didn't implemented.");
};
//import Vue from 'vue'
export default {
	install: function(app){
		app.config.globalProperties.$sknet = Sk.Net;
		app.config.globalProperties.$skui = Sk.UI;
		//app.config.globalProperties.$skconf = Sk.Config;
		app.config.globalProperties.$skutils = Sk.Utils;

		//for vue2.x
//		Vue.prototype.$sknet = Sk.Net;
//		Vue.prototype.$skui = Sk.UI;
//		Vue.prototype.$skconf = Sk.Config;
//		Vue.prototype.$skutils = Sk.Utils;
	}
};