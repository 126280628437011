<template>
	<div class="wfrmLoginM">
		<div class="wfrmLoginMt">星管家智能化物业综合管理软件</div>
		<div class="wfrmLoginMn">
			<div class="wfrmLoginMn_inp">
				<div class="wfrmLoginMn_inpIcon iconName"><input v-model="username" v-on:keyup.enter="commit"
						id="usernameinput" /></div>
			</div>
			<div class="wfrmLoginMn_inp">
				<div class="wfrmLoginMn_inpIcon iconPassword"><input type="password" v-model="password"
						v-on:keyup.enter="commit" /></div>
			</div>
			<div class="wfrmLoginMn_rember">
				<span><input type="checkbox" v-model="rememberAuth" /></span>记住我的登录状态
			</div>
			<div class="wfrmLoginMn_btn">
				<a id="commitbutton" v-on:click="commit">登 录</a>
			</div>
<!-- 			
			<div v-if="wfrmopts.havereg == 1" class="ucRegLogin-link"><a v-on:click="clickReg">立即注册</a>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;<a v-on:click="clickForgetP">忘记密码</a></div>
 -->		
 </div>
	</div>
</template>

<script>
	import md5 from 'js-md5';
	export default {
		name: 'wfrm_login',
		props: ["wfrmopts"],
		data: function() {
			return {
				username: "",
				password: "",
				rememberAuth: true,
				res: ""
			}
		},
		created() {
			this.getCookie();
		},
		methods: {
			commit: function() {
				//登录接口
				var self = this;
				
				if(this.username == null || this.username.trim() == ""){
					self.$message("请输入用户名");
					return;
				}
				if(this.password == null || this.password.trim() == ""){
					self.$message("请输入密码");
					return;
				}
				var req = {
					username: this.username,
					// password: md5.hex(this.password)
					password: this.password
				};
				this.$sknet.postJson(this.wfrmopts.auth.authUrl + 'login', req, function(res)  {
					//存储token
					if(self.rememberAuth){
						self.setCookie(self.username,self.password,7);
					}else{
						self.clearCookie();
					}	
					localStorage.setItem(self.$sknet.Config.saveToken + ".access", res.token);
					localStorage.setItem(self.$sknet.Config.saveToken + ".update", res.updateToken);
					location.reload();

				}, function(){
					self.$message("请确认用户名密码是否正确");
				}, null);
			},
			setCookie(c_name, c_pwd, exdays) {
				var exdate = new Date(); //获取时间
				exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
				//字符串拼接cookie
				window.document.cookie =
					"userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
				window.document.cookie =
					"userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
			},
			getCookie: function() {
				if (document.cookie.length > 0) {
					var arr = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
					for (var i = 0; i < arr.length; i++) {
						var arr2 = arr[i].split("="); //再次切割
						//判断查找相对应的值
						if (arr2[0] == "userName") {
							this.username = arr2[1]; //保存到保存数据的地方
						} else if (arr2[0] == "userPwd") {
							this.password = arr2[1];
						}
					}
				}
			},
			//清除cookie
			clearCookie: function() {
				this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
			},
			restore: function() {

			},

			save: function() {

			},
			clickForgetP:function(){
				this.$parent.showLogin = false;
				this.$parent.showForgetPass = true;
				this.$parent.showReg = false;
			},
			clickReg:function(){
				this.$parent.showLogin = false;
				this.$parent.showForgetPass = false;
				this.$parent.showReg = true;
			}
		},
		mounted: function() {
			this.restore();
		}
	}
</script>
<style>
	.wfrmLoginFullbg {
		width: 100%;
		height: auto;
		position: fixed;
		left: 0px;
		top: 0px;
		bottom: 0px;
		z-index: 990;
		background-color: #1d212d;
		background-image: url(./imgs/wfrmLoginBg.jpg);
		background-size: cover;
		background-position: center bottom;
	}

	.wfrmLoginM {
		width: 540px;
		height: auto;
		position: absolute;
		left: 50%;
		margin-left: -270px;
		top: 50%;
		margin-top: -230px;
		z-index: 200;
		background-color: rgba(255, 255, 255, 1);
		border-radius: 15px;
	}

	.wfrmLoginMt {
		width: auto;
		height: auto;
		margin: 0px;
		text-align: center;
		color: #333;
		font-size: 22px;
		padding: 50px 0px 30px;
	}

	.wfrmLoginMn {
		width: auto;
		height: auto;
		margin: 0px;
		padding: 0px 80px 40px;
	}

	.wfrmLoginMn_inp {
		width: auto;
		height: 46px;
		margin: 0px 0px 20px;
		background-color: #f4fbff;
		border-radius: 46px;
		overflow: hidden;
	}

	.wfrmLoginMn_inpIcon {
		width: auto;
		height: 36px;
		margin: 0px;
		padding-left: 45px;
		padding-top: 10px;
		background-repeat: no-repeat;
		background-position: 10px center;
	}

	.wfrmLoginMn_inpIcon.iconName {
		background-image: url(./imgs/login_icon_name.png);
	}

	.wfrmLoginMn_inpIcon.iconPassword {
		background-image: url(./imgs/login_icon_password.png);
	}

	.wfrmLoginMn_inpIcon input {
		width: 100%;
		height: 26px;
		border: 0px;
		background-color: #f4fbff;
		color: #333;
		font-size: 13px;
		line-height: 26px;
	}

	.wfrmLoginMn_rember {
		width: auto;
		height: 50px;
		margin: 0px 10px;
		line-height: 20px;
	}

	.wfrmLoginMn_rember span {
		width: auto;
		height: auto;
		display: block;
		float: left;
		margin: 0px 5px;
	}

	.wfrmLoginMn_btn {
		width: auto;
		height: auto;
		margin: 0px 0px 15px;
	}

	.wfrmLoginMn_btn a,
	.wfrmLoginMn_btn a:visited {
		width: 100%;
		height: 46px;
		display: block;
		margin: 0px;
		color: #fff;
		font-size: 16px;
		text-align: center;
		line-height: 46px;
		background-color: #4b9cf8;
		border-radius: 46px;
		cursor: pointer;
	}

	.wfrmLoginMn_btn a:hover {
		background-color: #4b9cf8;
		color: #fff;
		text-decoration: none;
	}

	.wfrmLoginMn_err {
		width: auto;
		height: auto;
		margin: 0px;
		font-size: 13px;
		text-align: center;
		line-height: 25px;
		min-height: 25px;
		color: #ff5c5c;
		display: none;
	}

	.wfrmLoginM input {
		caret-color: #333;
		text-fill-color: #333;
		-webkit-text-fill-color: #333;
	}

	.wfrmLoginM input:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px #f4fbff inset;
		-webkit-text-fill-color: $cursor !important;
	}
	.ucRegLogin-link{ width: auto; height: 20px; margin: 0px 80px; line-height: 20px; text-align:center; color:#e5e5e5;}
	.ucRegLogin-link a, .ucRegLogin-link a:visited{ color:#999;}
	.ucRegLogin-link a:hover{ color:#5e7ce0;}
</style>
