<template>
  <wfrm_frame v-bind:wfrmopts="wfrmopts"/>
</template>

<script>
import {ElConfigProvider} from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default {
  name: 'App',
  components: {
	  [ElConfigProvider.name]:ElConfigProvider,//添加组件
  },
  data: function(){return{
	locale:zhCn,//给locale赋值
    wfrmopts:{
      widget: {
        home:{
          url: "http://www.yunfengtech.com"
        },
        links:{
          links:[{name: "云峰主页", url: "http://www.yunfengtech.com"}, {name: "内网工具", url: "http://192.168.3.3"}]
        }
      },
      auth:{ 
        authUrl: this.$skconf("wfrm_auth_url"),
      },
      pdl:{
        urlMenus: this.$skconf("wfrm_menu_url"),
        compBasePath: "assets/"
      },
	  platform:this.$skconf("surl"),
	  validityTimes:60,//验证码有效时间60s
	  havereg:2  //1有注册功能  2  无注册功能
    },
    routes:[],
    lesteps:true
  }},
  beforeMount: function(){
    this.$sknet.Config.useToken = true;
    this.$sknet.Config.updateTokenUrl = this.$skconf("sknet_update_token_url");
 
	this.$sknet.Config.saveToken="sk.regtoken";
	this.$sknet.Config.maskFlag = true;
	this.$sknet.Config.defaultErrorFunction= function(res){
		if(typeof res.reason == "string"){
			if(res.reason.length > 4){
				var errCode = res.reason.slice(0,4);
				if(errCode == "-108" || errCode == "-205" || errCode == "-206" ){
					this.$alert('登录超时，请重新登录', '提示', {
						confirmButtonText: '确定',
						callback: action => {
							location.reload();
						}
					});
					return;
				}
			}
		}
		
		this.$alert(res.reason, '操作失败', {
			confirmButtonText: '确定'
		});
	};
	
    this.$skui.showDlgC = function(){};
  }
}
</script>

<style>

</style>
