<template>
	<div>
		<div class="ucPanel bgWhite radius" style="padding:0px 15px 5px; margin-bottom:15px;">
			<div class="ucForm ucForm-flow ucBtn-bg">

				<div class="ucForm-group">
					<input name="" type="text" class="ucForm-control" v-model="searchname" placeholder="请输入管理部门名称" />
				</div>
				<div class="ucForm-group">
					<select class="ucForm-control" name="public-choice" v-model="type">
						<option :value="coupon.typeid" v-for="coupon in typeList" v-bind:key="coupon.typeid">
							{{coupon.typename}}
						</option>
					</select>
				</div>
				<div class="ucForm-group frBtn">
					<button class="ucBtn ucBtn-search ucBtn-blue" v-on:click="search"> <i class="iImg"></i>查询</button>
				</div>
				<div class="clear"></div>
			</div>
		</div>

		<div class="ucPanel bgGrayWhite" style="padding:10px;">
			<button class="ucBtn ucBtn-edit ucBtn-blue" v-on:click="updateItems"><i class="iImg"></i>批量修改 </button>
			<button class="ucBtn ucBtn-del ucBtn-blue" v-on:click="deleteItems"><i class="iImg"></i>批量删除</button>
			<button class="ucBtn ucBtn-add ucBtn-blue" v-on:click="addItem"><i class="iImg"></i>添加</button>
		</div>

		<div class="ucPanel bgWhite radius"
			style="padding:15px; border-top-left-radius: 0px; border-top-right-radius: 0px; ">
			<div class="ucPanel ucPanel-table">

				<el-table :data="items" class="eltablegroup" :row-style="{padding: '6px 8px',height:'24'}" style="width: 100%;margin-bottom: 20px;font-size: 13px;,line-height: '24px'" row-key="id" ref="districtTable" border
					:select-on-indeterminate="true" default-expand-all @selection-change="selectionLineChangeHandle"
					:tree-props="{children: 'children', hasChildren: 'hasChildren'}" @select-all="selectAll">
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column prop="name" label="名称">
					</el-table-column>
					<el-table-column prop="parentName" label="上级组织">
					</el-table-column>
					<!-- <el-table-column prop="leaderName" label="部门领导">
					</el-table-column>
					<el-table-column prop="fgLeaderName" label="部门分管领导">
					</el-table-column> -->
					<el-table-column prop="typeName" label="类型" width="80">
					</el-table-column>
					<!-- <el-table-column prop="code" label="编码">
					</el-table-column> -->
					<el-table-column prop="orderCode" label="顺序码" width="100">
					</el-table-column>
					<el-table-column prop="description" label="描述">
					</el-table-column>


					<el-table-column fixed="right" label="操作" width="240">
						<template #default="scope">
							<button type="submit" class="ucBtn ucBtn-edit ucBtn-blue ucbtn-icon" title="编辑"
								@click="editInfo(scope.$index, scope.row)"><i class="iImg"></i>编辑 </button>
							<button type="submit" class="ucBtn ucBtn-see ucBtn-blue ucbtn-icon" title="查看"
								@click="viewInfo(scope.$index, scope.row)"><i class="iImg"></i>查看 </button>
							<button type="submit" class="ucBtn ucBtn-del ucBtn-blue ucbtn-icon" title="删除"
								@click="deleteInfo(scope.$index, scope.row)"><i class="iImg"></i>删除 </button>
						</template>
					</el-table-column>


					<!-- <el-table-column>
                <template slot="header">
                    操作
                </template>
                <template #default="scope">
                    <button type="submit" class="ucBtn ucBtn-edit ucBtn-blue ucbtn-icon" title="编辑" @click="editInfo(scope.$index, scope.row)"><i class="iImg"></i>编辑 </button>
                    <button type="submit" class="ucBtn ucBtn-see ucBtn-blue ucbtn-icon" title="查看" @click="viewInfo(scope.$index, scope.row)"><i class="iImg"></i>查看 </button>
                    <button type="submit" class="ucBtn ucBtn-del ucBtn-blue ucbtn-icon" title="删除" @click="deleteInfo(scope.$index, scope.row)"><i class="iImg"></i>删除 </button>
                </template>
            </el-table-column> -->
				</el-table>
			</div>

		</div>


		<um_group_info @closeDialogGroupinfo="closeDialogGroupinfo" v-if="groupinfoDialogVisible"></um_group_info>
		<um_group_des @closeDialogUpdateGroupitems="closeDialogUpdateGroupitems" v-if="updategroupitemsDialogVisible">
		</um_group_des>
	</div>
</template>
<script>
	import um_group_info from './um_group_info.vue'
	import um_group_des from './um_group_des.vue'

	export default {
		name: 'um_group_list',
		props: {
			msg: String
		},
		data: function() {
			return {
				typeList: [{
					"typename": "行政",
					"typeid": "xz"
				}, {
					"typename": "党组织",
					"typeid": "dzz"
				}, {
					"typename": "管理区",
					"typeid": "glq"
				}, {
					"typename": "数据权限",
					"typeid": "sjqx"
				}],
				type: "xz",
				searchname: "", //模糊搜索 用户名、姓名
				//数据
				items: [],
				groupinfoDialogVisible: false,
				updategroupitemsDialogVisible: false,
				item: {},
				id: "",
				dataonLineListSelections: [],
				ids: []
			}
		},
		created() {
			this.search();
		},
		methods: {
			search: function() {
				this.dataonLineListSelections = [];
				var self = this;
				var data = {
					cmd: "brum_searchGroupTreeWithParent"
				};
				data.keywords = this.searchname;
				data.type = this.type;
				this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data) {
					var items = data.items;
					var result = [];
					if (typeof items != "undefined") {
						for (var i = 0; i < items.length; i++) {
							result.push(self.convertBean(items[i]));
						}
					}
					
					if(self.searchname == null || self.searchname.trim() == ""){
						self.items = self.changedatTotree(result, 0);
					}else{
						self.items = result;
					}
					
					
				}, null, self);
			},
			convertBean: function(data) {
				var obj = {};
				obj.check = false; //选中true
				obj.id = data.id || "";
				obj.name = data.name || "";
				obj.parentName = data.parentName || "";
				obj.leaderName = data.leaderName || "";
				obj.fgLeaderName = data.fgLeaderName || "";
				obj.typeName = data.typeName || "";
				obj.type = data.type || "";
				obj.code = data.code || "";
				obj.orderCode = data.orderCode || "";
				obj.description = data.description || "";
				obj.pid = data.parentId || 0;
				obj.leaderUid = data.leaderUid || "";
				obj.fgLeaderUid = data.fgLeaderUid || "";
				obj.branch = data.branch || "";
				return obj;
			},
			selectionLineChangeHandle(val) {
				this.dataonLineListSelections = val
			},
			changedatTotree: function(data, parentId) {
				var itemArr = [];
				for (var i = 0; i < data.length; i++) {
					var node = data[i];
					if (node.pid === parentId) {
						var newNode = node;
						newNode.children = this.changedatTotree(data, node.id);
						itemArr.push(newNode);
					}
				}
				return itemArr;
			},
			selectAll(selection, first) {
				if (!first) {
					this.isAllSelect = !this.isAllSelect;
				}
				selection.map(el => {
					if (el.children) {
						el.children.map(j => {
							this.toggleSelection(j, this.isAllSelect);
						});
						if (el.children.length > 0) {
							this.selectAll(el.children, true);
						}
					}
				});
			},
			getchooseids: function() {
				var ids = [];
				for (var i = 0; i < this.dataonLineListSelections.length; i++) {
					//console.log('id:'+this.dataonLineListSelections[i].id)
					ids.push(this.dataonLineListSelections[i].id);
				}
				return ids;
			},
			toggleSelection(row, select) {
				if (select) {
					this.$refs.districtTable.toggleRowSelection(row, select);
				} else {
					this.$refs.districtTable.clearSelection();
				}
			},
			closeDialogUpdateGroupitems: function() {
				this.ids = [];
				this.updategroupitemsDialogVisible = false;
				this.search();
			},
			updateItems: function() {
				var ids = this.getchooseids();
				if (ids == null || ids.length == 0) {
					self.$message('没有选中的组织');
					return;
				}
				this.ids = ids;
				this.updategroupitemsDialogVisible = true;
			},
			deleteItems: function() {
				this.$confirm('您确定要删除该组织吗？与此组织相关的用户组和权限将同步删除。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.realDeleteModInfoBatch();
				}).catch(() => {});

			},
			realDeleteModInfoBatch: function() { //实际删除
				var self = this;
				var ids = this.getchooseids();
				if (ids == null || ids.length == 0) {
					self.$message('没有选中的组织');
					return;
				}
				var data = {
					cmd: 'brum_deleteGroupBatch'
				};
				data.ids = ids;
				this.$sknet.postJsonSync(this.$skconf("brum.surl"), data, function(data) {
					data.reason;
					self.search();
				}, null, self);
			},
			closeDialogGroupinfo: function() {
				this.item = {};
				this.id = "";
				this.mod = "";
				this.groupinfoDialogVisible = false;
				this.search();
			},
			addItem: function() {
				this.item = {};
				this.id = "";
				this.mod = "add";
				this.groupinfoDialogVisible = true;
			},
			editInfo: function(index, item) {
				this.item = item;
				this.id = item.id + "";
				this.mod = "edit";
				this.groupinfoDialogVisible = true;
			},
			viewInfo: function(index, item) {
				this.item = item;
				this.id = item.id + "";
				this.mod = "view";
				this.groupinfoDialogVisible = true;
			},
			deleteInfo: function(index, item) {
				var self = this;
				this.$confirm('您确定要删除该组织吗？与此组织相关的用户组和权限将同步删除。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var data = {
						cmd: 'brum_deleteGroup'
					};
					data.id = item.id;
					this.$sknet.postJsonSync(this.$skconf("brum.surl"), data, function(data) {
						data.reason;
						self.search();
					}, null, self);
				}).catch(() => {});
			}
		},
		components: {
			um_group_info,
			um_group_des
		},
		mounted: function() {}
	}
</script>
<style>
.el-table.eltablegroup, .el-table.eltablegroup table{ font-size: 13px; }
.el-table.eltablegroup td, .el-table.eltablegroup th{padding: 6px 8px;  line-height: 24px; }
</style>