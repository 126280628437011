<template>
	<el-dialog title="批量修改" v-model="groupinfoDialogVisible" width="60%" center append-to-body
		@close='closeDialogGroupinfo'>
		<div class="ucForm ucForm-lump auto-d2">
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">机构名称</label>
					<input class="ucForm-control" v-model="name" :disabled="viewOnly">
				</div>
			</div>
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">上级组织</label>
					<select class="ucForm-control" name="public-choice" v-model="group" :disabled="viewOnly">
						<option :value="coupon.id" v-for="coupon in groups" v-bind:key="coupon.id">{{coupon.name}}
						</option>
					</select>
				</div>
			</div>
			<!-- <div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">部门领导</label>
					<input type="text" class="ucForm-control" v-model="trueName" :disabled="viewOnly" v-on:click="cha1"
						readonly />
					<input type="hidden" id="userid" name="psUserId" v-model="userIds">
				</div>
			</div>
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">分管领导</label>
					<input type="text" class="ucForm-control" v-model="fgLeaderName" :disabled="viewOnly"
						v-on:click="cha2" readonly />
					<input type="hidden" id="fgLeaderUid" name="fgLeaderUid" v-model="fgLeaderUid">
				</div>
			</div> -->
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">类型</label>
					<select class="ucForm-control" name="public-choice" v-model="type" :disabled="viewOnly">
						<option :value="coupon.typeid" v-for="coupon in typeList" v-bind:key="coupon.typeid">
							{{coupon.typename}}</option>
					</select>
				</div>
			</div>
			<!-- <div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">编码</label>
					<input class="ucForm-control" v-model="code" :disabled="viewOnly">
				</div>
			</div> -->
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">顺序码</label>
					<input class="ucForm-control" v-model="orderCode" :disabled="viewOnly">
				</div>
			</div>

			<div class="ucForm-group-ad auto-d100">
				<div class="ucForm-group">
					<label class="ucForm-label">描述</label>
					<textarea class="ucForm-control" rows="6" v-model="description" :disabled="viewOnly"></textarea>
				</div>
			</div>
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">是否为分公司</label>
					<select class="ucForm-control" name="public-choice" v-model="branch" :disabled="viewOnly">
						<option :value="coupon.id" v-for="coupon in branchList" v-bind:key="coupon.id">{{coupon.name}}
						</option>
					</select>
				</div>
			</div>
			<div v-if="viewOnly == false" class="ucForm-group-ad ucForm-group-btn auto-d100">
				<div class="ucForm-group ucBtn-bg">
					<button class="ucBtn ucBtn-ok ucBtn-blue ucBtn-wSmall" v-on:click="commit"><i
							class="iImg"></i>确定</button>
				</div>
			</div>
			<div class="clear"></div>
			<!-- <um_group_chooseuser @closeDialogchoose_leader="closeDialogchooseleader(arguments)" v-if="chooseleaderDialogVisible"></um_group_chooseuser> -->
			<el-dialog title="选择领导" v-model="chooseleaderDialogVisible" width="60%" center append-to-body
				@close='closeDialogchooseleader'>

				<div class="ucPanel bgWhite radius" style="padding:0px 15px 5px; margin-bottom:15px;">
					<div class="ucForm ucForm-flow ucBtn-bg">
						<div class="ucForm-group">
							<label class="ucForm-label">用户名/姓名</label>
							<input name="" type="text" class="ucForm-control" v-model="searchName"
								placeholder="请输入用户名/姓名" />
						</div>
						<div class="ucForm-group frBtn">
							<button class="ucBtn ucBtn-search ucBtn-blue" v-on:click="search"><i
									class="iImg"></i>查询</button>
						</div>
						<div class="clear"></div>
					</div>
				</div>
				<div class="ucPanel bgWhite radius"
					style="padding:15px; border-top-left-radius: 0px; border-top-right-radius: 0px; ">
					<div class="ucPanel ucPanel-table">
						<table width="100%" border="0" cellspacing="0" cellpadding="0" class="ucTable border">
							<thead>
								<tr>
									<th class="thId">ID</th>
									<th>姓名</th>
									<th>用户名</th>
									<th>性别</th>
									<th>组织机构</th>
									<th>角色</th>
									<th class="thBtn">操作</th>

								</tr>
							</thead>
							<tbody v-for="(val,index) in items" v-bind:key="val.userid">
								<tr data-bind="click: $parent.rowClick,css:{'datagrid-row-selected':rowsel()}">
									<td>{{ index }}</td>
									<td>{{ val.trueName }}</td>
									<td>{{ val.username }}</td>
									<td>{{ val.gender }}</td>
									<td>{{ val.organization }}</td>
									<td>{{ val.role }}</td>
									<td v-on:click="addInfo(val)">选择</td>
								</tr>
							</tbody>
							<tbody v-if="items.length == 0">
								<tr>
									<td colspan="7">暂无数据</td>
								</tr>
							</tbody>

						</table>
					</div>
					<el-pagination @current-change="handlePageChangeU"
						v-model:currentPage="currentPageU" :page-size="pageSize" layout="prev, pager, next, jumper"
						:total="totalU">
					</el-pagination>
				</div>
			</el-dialog>
		</div>

	</el-dialog>
</template>
<script>
	// import um_group_chooseuser from './um_group_chooseuser.vue'

	export default {
		name: "um_group_info",
		data: function() {
			return {
				branchList: [{
					"name": "是",
					"id": 1
				}, {
					"name": "否",
					"id": 2
				}],
				branch: "",
				typeList: [{
						"typename": "行政",
						"typeid": "xz"
					},
					{
						"typename": "党组织",
						"typeid": "dzz"
					},
					{
						"typename": "管理区",
						"typeid": "glq"
					},
					{
						"typename": "数据权限",
						"typeid": "sjqx"
					}
				],
				groupinfoDialogVisible: false,
				mod: this.$parent.mod,
				id: this.$parent.id,
				item: this.$parent.item,
				type: "xz",
				trueName: "",
				userIds: "",
				groups: [],
				group: "",
				fgLeaderName: "",
				fgLeaderUid: "",
				name: "",
				code: "",
				orderCode: "",
				description: "",
				viewOnly: false,
				leadviewOnly: false,
				chooseleaderDialogVisible: false,
				choosetype: "",
				items: [],
				searchName: "",
				pageSize:10,
				currentPageU: 1,
				totalU: 0,
			}
		},
		created() {
			this.groupinfoDialogVisible = true;
			this.clearinfo();
			this.fetchGroup();
			//设置 viewOnly
			if (this.mod == 'edit') {
				this.viewOnly = false;
				this.setvalue();
			}
			if (this.mod == 'view') {
				this.viewOnly = true;
				this.setvalue();
			}
			if (this.mod == 'add') {
				this.viewOnly = false;
			}
		},
		methods: {
			handlePageChangeU(val) {
				this.currentPageU = val
				this.search()
			},
			addInfo: function(item) {
				var userid = item.userid;
				var trueName = item.trueName;
				if (this.choosetype == "leader") {
					this.userIds = userid;
					this.trueName = trueName;
				}
				if (this.choosetype == "fgleader") {
					this.fgLeaderUid = userid;
					this.fgLeaderName = trueName;
				}
				this.closeDialogchooseleader();
			},
			search: function() {
				var self = this;
				var data = {
					cmd: "brum_searchUsers"
				};
				data.start = (this.currentPageU - 1) * this.pageSize;
				data.count = this.pageSize;
				data.searchName = this.searchName;
				this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data) {
					var items = data.items;
					var result = [];
					if (typeof items != "undefined") {
						for (var i = 0; i < items.length; i++) {
							result.push(self.convertBean(items[i]));
						}
					}
					self.items = result;
					self.totalU = data.totalCount;
				}, null, self);
			},
			convertBean: function(data) {
				var obj = {};
				obj.index = data.index; //自动序号
				obj.rowsel = false; //单选
				obj.check = false; //多选
				obj.userid = data.id || "";
				obj.username = data.username || "";
				obj.trueName = data.name || "";
				obj.gender = data.gender == 1 ? "男" : "女";
				obj.organization = data.groupNames || "";
				obj.role = data.roleNames || "";
				return obj;
			},
			closeDialogchooseleader: function() {
				this.chooseleaderDialogVisible = false;
			},
			setvalue: function() {
				this.branch = this.item && this.item.branch; //分公司 
				this.type = this.item && this.item.type;
				this.userIds = this.item && this.item.leaderUid;
				this.fgLeaderName = this.item && this.item.fgLeaderName; //部门分管领导名称
				this.fgLeaderUid = this.item && this.item.fgLeaderUid; //部门分管领导id
				this.code = this.item && this.item.code;
				this.orderCode = this.item && this.item.orderCode;
				this.description = this.item && this.item.description;
				this.trueName = this.item && this.item.leaderName; //部门领导名称
				this.name = this.item && this.item.name;
			},
			fetchGroup: function() {
				var self = this;
				var data = {
					cmd: "brum_chooseGroupsTree"
				};
				this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data) {
					if (data.items && data.items.length > 0) {
						self.formatGroups(data.items, 0);
						if (self.item != undefined) {
							self.group = (self.item.pid != 0) ? self.item.pid : undefined;
						} else {
							self.group = "";
						}
					}
				}, null, self);
			},
			formatGroups(items, level) {
				var tmp = null;
				var preTreeNodeName = this.getTreeLevel(level);
				for (var i = 0; i < items.length; i++) {
					tmp = items[i];
					tmp.name = preTreeNodeName + tmp.name + "(" + tmp.typeName + ")";
					this.groups.push(tmp);
					if (tmp.subGroups && tmp.subGroups.length > 0) {
						this.formatGroups(tmp.subGroups, ++level);
					}
				}
			},
			getTreeLevel: function(level) {
				var namePre = "";
				while (level > 0) {
					namePre += "|—";
					level--;
				}
				return namePre;
			},
			commit: function() {
				var self = this;

				var data = {};
				if (self.id <= 0) {
					data.cmd = "brum_addGroup";
				} else {
					data.id = self.id;
					data.cmd = "brum_editGroup";
				}
				if (typeof self.name == "undefined" || self.name == "") {
					self.$message("请输入机构名称！");
					return;
				}

				/* if (typeof self.trueName == "undefined" || self.trueName == "") {
					self.$message("请输入部门领导！");
					return;
				}
				if (typeof self.type == "undefined" || self.type == "") {
					self.$message("请选择类型！");
					return;
				}
				if (typeof self.code == "undefined" || self.code == "") {
					self.$message("请输入编码！");
					return;
				} */
				if (typeof self.orderCode == "undefined" || self.orderCode == "") {
					self.$message("请输入顺序码！");
					return;
				}
				if (self.orderCode < 1) {
					self.$message("顺序码不能小于1！");
					return;
				}
				data.branch = self.branch; //分公司
				data.name = self.name;
				data.parentId = self.group;
				data.type = self.type;
				//data.code = self.code;
				data.orderCode = self.orderCode;
				data.description = self.description;
				//data.leaderUid = self.userIds;
				//data.fgLeaderUid = self.fgLeaderUid;
				this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data) {
					data.reason;
					if (self.id <= 0) {
						self.$message("添加成功");
					} else {
						self.$message("修改成功");
					}
					self.closeDialogGroupinfo();
				}, null, self);
			},
			closeDialogGroupinfo: function() {
				this.groupinfoDialogVisible = true;
				this.clearinfo();
				this.$emit('closeDialogGroupinfo'); //通知父组件改变。
			},
			clearinfo: function() {
				this.branch = ""; //分公司 
				this.type = "";
				this.userIds = "";
				this.fgLeaderName = ""; //部门分管领导名称
				this.fgLeaderUid = ""; //部门分管领导id
				this.code = "";
				this.orderCode = "";
				this.description = "";
				this.trueName = ""; //部门领导名称
				this.name = "";
			},
			cha1: function() {
				this.currentPageU = 1;
				this.totalU = 0;
				this.searchName = "";
				this.search();
				this.choosetype = "leader";
				this.chooseleaderDialogVisible = true;
			},
			cha2: function() {
				this.currentPageU = 1;
				this.totalU = 0;
				this.searchName = "";
				this.search();
				this.choosetype = "fgleader";
				this.chooseleaderDialogVisible = true;
			}
		},
		mounted() {

		},
		components: {
			// um_group_chooseuser
		}
	}
</script>
