export default {
    install: (app) =>{
        var config = {
			wfrm_auth_url: "https://pms.xhjr.tech/frm/common/",
			wfrm_menu_url: "https://pms.xhjr.tech/s/main?cmd=brpd_getPageInfo&pageId=26",
			sknet_update_token_url: "https://pms.xhjr.tech/frm/common/updateToken",
			
			// wfrm_auth_url: "http://localhost:9700/frm/common/",
			// wfrm_menu_url: "http://localhost:9700/s/main?cmd=brpd_getPageInfo&pageId=26",
			// sknet_update_token_url: "http://localhost:9700/frm/common/updateToken",
			
            moduleConfig:{
				
				surl: "https://pms.xhjr.tech/s/main",
				report:"https://pms.xhjr.tech",
				cmsurl: "https://pms.xhjr.tech/s/cms",
				oss:{
                   uurl: "https://pms.xhjr.tech/oss/fileupload",
                   vurl: "https://pms.xhjr.tech/oss/file/"
                }
				
				// surl: "http://localhost:9700/s/main",
				// cmsurl: "http://localhost:9700/s/cms",
				// report:"http://localhost:9700",
				// oss:{
				//      uurl: "http://localhost:9700/oss/fileupload",
				//      vurl: "http://localhost:9700/oss/file/"
				// }
            }
        };
        
        var valueOfObject = function(obj, key){
            if(obj && key){
                var fields = key.split(".");
                var el = obj;
                for(var i = 0; i < fields.length; i ++){
                    el = el[fields[i]];
                    if(typeof(el) == "undefined" || el == null){
                        return el;
                    }
                }
                
                return el;
            }
        };

        var getConfig = function(key){
			
            var index = key.indexOf(".");
            if(index > 0){
                var module = key.substring(0,index);
				var remainKey = key.substring(index +1);
				//通用的业务模块配置
				return valueOfObject(config.moduleConfig, remainKey);
            }else{
				return config[key];
			}
			return "";
        }

        //在Vue中注册$skconf。必须使用此名称。
        app.config.globalProperties.$skconf = getConfig;
    }
}