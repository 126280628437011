<template>
  <el-dialog
  title="批量修改备注"
  v-model="updategroupitemsDialogVisible"
  width="30%"
  center append-to-body @close='closeDialogUpdateGroupitems'>

<div class="ucForm ucForm-lump auto-d1"> 
      <div class="ucForm-group-ad">
          <div class="ucForm-group">
             <label class="ucForm-label">描述</label>
             <textarea rows="3" cols="" class="ucForm-control" v-model="description"></textarea>
          </div>
      </div>
      <div class="ucForm-group-ad ucForm-group-btn auto-d100">
          <div class="ucForm-group ucBtn-bg">
             <button class="ucBtn ucBtn-ok ucBtn-blue ucBtn-wSmall" v-on:click="commit"><i class="iImg"></i>确定</button>
             <button class="ucBtn ucBtn-refresh ucBtn-gray ucBtn-wSmall" v-on:click="closeDialogUpdateGroupitems"><i class="iImg"></i>关闭</button>
          </div>
      </div>
      <div class="clear"></div>
</div>
</el-dialog>
</template>

<script>
  export default {
    name: "um_group_des",
    data: function(){return{
            description:"",
            ids:this.$parent.ids,
            updategroupitemsDialogVisible:false
    }}, 
    created () {   
        this.updategroupitemsDialogVisible = true;
        this.clearinfo();
    },
    methods: {
        commit:function(){
            var self = this;
            var data = {};
            data.cmd = "brum_editGroupBatch";
            let items = this.ids;
			if(items.length <= 0){
                self.$message("请添加批量修改的选项！");
				return;
			}else {
                data.ids = items;
			}
            data.description = self.description||"";
			this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data){
                data.reason;
                self.$message("修改成功");
				self.closeDialogUpdateGroupitems();
			}, function(data) {
				self.$message(data.reason);
			}, self);
        },
        closeDialogUpdateGroupitems: function(){
            this.updategroupitemsDialogVisible = false;
            this.clearinfo();
            this.$emit('closeDialogUpdateGroupitems'); //通知父组件改变。
        },
        clearinfo:function(){
            this.description = "";
        }
    },
    mounted(){
        
    },
    components: {
        
    }
  }
</script>

<style scoped>

</style>