<template>
  <el-dialog
  title="批量修改"
  v-model="updateitemsDialogVisible"
  width="30%"
  center append-to-body @close='closeDialogUpdateitems'>

<div class="ucForm ucForm-lump auto-d1"> 
      <div class="ucForm-group-ad">
          <div class="ucForm-group">
             <label class="ucForm-label">性别</label>
             <select class="ucForm-control" name="public-choice" v-model="gender">                                        
                <option :value="coupon.id" v-for="coupon in genders" v-bind:key="coupon.id">{{coupon.name}}</option>                                    
            </select>
          </div>
      </div>
      <div class="ucForm-group-ad">
          <div class="ucForm-group">
             <label class="ucForm-label">密码</label>
             <input class="ucForm-control" v-model="password" type="password"  >
          </div>
      </div>
      <div class="ucForm-group-ad ucForm-group-btn auto-d100">
          <div class="ucForm-group ucBtn-bg">
             <button class="ucBtn ucBtn-ok ucBtn-blue ucBtn-wSmall" v-on:click="commit"><i class="iImg"></i>确定</button>
             <button class="ucBtn ucBtn-refresh ucBtn-gray ucBtn-wSmall" v-on:click="closeDialogUpdateitems"><i class="iImg"></i>关闭</button>
          </div>
      </div>
      <div class="clear"></div>
</div>
</el-dialog>
</template>

<script>
  export default {
    name: "um_user_update",
    data: function(){return{
            genders:[{id : 1,name : '男'},{id : 2, name : '女'}],
            gender:"",
            password:"",
            updateitemsDialogVisible:false,
            checkItem:this.$parent.checkItem
    }}, 
    created () {   
        this.updateitemsDialogVisible = true;
        this.clearinfo();
    },
    methods: {
        commit:function(){
            var self = this;
            var data = {};

            data.cmd = "brum_editUserBatch";
            let items = self.checkItem;
			if(items.length <= 0){
                self.$message("请添加批量修改的选项！");
				return;
			}else {
				var ids = new Array();
				for(var i = 0; i < items.length; i ++){
					ids.push(items[i].id);
				}
				data.ids = ids;
			}
            data.password = self.password||"";
            data.gender = self.gender||"";
			this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data){
                data.reason;
                self.$message("修改成功");
				self.closeDialogUpdateitems();
			}, function(data) {
				if(data.reason && data.reason == "用户名已存在") {
					self.$message(data.reason);
				}
			}, self);
        },
        closeDialogUpdateitems: function(){
            this.clearinfo();
            this.$emit('closeDialogUpdateitems'); //通知父组件改变。
        },
        clearinfo:function(){
            this.gender = "";
            this.password = "";
        }
    },
    mounted(){
        
    },
    components: {
        
    }
  }
</script>

<style scoped>

</style>