<template>
  <el-dialog
  title="角色信息"
  v-model="roleDialogVisible"
  width="30%"
  center append-to-body @close='closeDialogRole'>


<div class="ucForm ucForm-lump auto-d1"> 
      <div class="ucForm-group-ad">
          <div class="ucForm-group">
             <label class="ucForm-label">角色名称</label><input class="ucForm-control" v-model="name" :disabled="viewOnly">
          </div>
      </div>
      <div class="ucForm-group-ad">
          <div class="ucForm-group">
             <label class="ucForm-label">角色描述</label>
             <textarea rows="3" cols="" class="ucForm-control" v-model="description" :disabled="viewOnly"></textarea>
          </div>
      </div>
      <div v-if="viewOnly == false" class="ucForm-group-ad ucForm-group-btn auto-d100">
          <div class="ucForm-group ucBtn-bg">
             <button class="ucBtn ucBtn-ok ucBtn-blue ucBtn-wSmall"  v-on:click="commit"><i class="iImg"></i>确定</button>
          </div>
      </div>
      <div class="clear"></div>
</div>
</el-dialog>
</template>

<script>
  export default {
    name: "um_role_info",
    data: function(){return{
            description:"",
            name:"",
            viewOnly:false,
            mod:this.$parent.mod,
            id:this.$parent.id,
            roleDialogVisible : false
    }}, 
    created () {   
        this.clearinfo();
        this.roleDialogVisible = true;
        //设置 viewOnly
        if(this.mod=='edit' ){
            this.viewOnly = false;
            this.fetch();
        }
        if(this.mod=='view'){
            this.viewOnly = true;
            this.fetch();

        }
        if(this.mod=='add'){
            this.viewOnly = false;
        }
    },
    methods: {
        fetch:function(){
            var self = this;
            var data = {cmd: 'brum_getRoleById', id: self.id};
			this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data){
				var item = data.role;
				self.name = item.name||"";
				self.description = item.description||"";
			}, null, self);
        },
        commit:function(){
            var self = this;

            var data = {};
            if(self.id > 0){
                data.cmd = "brum_editRole";
                data.id = self.id;
            }else{
                data.cmd = "brum_addRole";
            }
            data.name = self.name||"";
            data.description = self.description||"";
            this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data){
                data.reason;
                if(self.id <= 0){
                    self.$message("添加成功");
                }else{
                    self.$message("修改成功");
				}
				self.closeDialogRole();
			}, null, self);
        },
        closeDialogRole: function(){
            this.roleDialogVisible = false;
            this.clearinfo();
            this.$emit('closeDialogRole'); //通知父组件改变。
        },
        clearinfo:function(){
            this.description = "";
            this.name = "";
        }
    },
    mounted(){
        
    },
    components: {
        
    }
  }
</script>

<style scoped>

</style>