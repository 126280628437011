<template>
	<div class="ucMenu linear">
		<ul>
			<li v-for="menu in menus" v-bind:key="menu.id" v-bind:class="{'liCurr': menu.open}">
				<dl class="dlT" v-on:click="clickMenu(menu)">
					<dt><img v-bind:src="menu.icon" /></dt>
					<dd>{{menu.name}}</dd>
					<i class="iArr"></i>
				</dl>
				<dl class="dlM">
					<dl class="dlMn" v-for="submenu in menu.subMenus" v-bind:key="submenu.id"
						v-on:click="clickSubMenu(menu,submenu)" v-bind:class="{'dlMnCurr': submenu.selected}">
						<dt v-show="submenu.icon"><!-- <img v-bind:src="submenu.icon" /> --></dt>
						<dd>{{submenu.name}}</dd>
					</dl>
				</dl>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'wfrm_menu_tree',
		props: ['menus'],
		data: function() {
			return {
				links: [],
				curMenu: "",
				curSubMenu: ""
			}
		},
		created() {
			var links = [];
			links.push({
				name: "云峰主页",
				url: "http://www.yunfengtech.com"
			});
			links.push({
				name: "内网工具",
				url: "http://192.168.3.3"
			});
			this.links = links;
		},
		// watch: {
		// 	menus: {
		// 		immediate: true,
		// 		handler(newVal) {
		// 			this.menus = newVal;
		// 		},
		// 		deep: true
		// 	}
		// },
		methods: {
			clickMenu: function(menu) {
				//控制是否展开
				menu.open = !menu.open;
			},
			clickSubMenu: function(menu, submenu) {
				//遍历所有submenu，清理和设置选中标志
				// for(var i = 0; i < this.menus.length; i ++){
				//     for(var j = 0; j < this.menus[i].subMenus.length; j ++){
				//         if(this.menus[i].subMenus[j].id == submenu.id && this.menus[i].id == menu.id){
				//             this.menus[i].subMenus[j].selected = true;
				//         }else{
				//             this.menus[i].subMenus[j].selected = false;
				//         }
				//     }
				// }
				//通知外部点击事件
				this.$emit('click-submenu', menu, submenu);
			},
		}
	}
</script>
