<template>
    <el-dialog
    title="分配权限"
    v-model="rolePermDialogVisible"
    width="50%"
    center append-to-body @close='closeDialogrolePerm'>
        <div class="ucPanel" style="height: 200px; overflow: auto; margin-bottom: 10px;">
            <el-tree ref="tree" node-key="id" :data="data" :props="defaultProps" @node-click="handleNodeClick" show-checkbox ></el-tree>
        </div>    
        <div class="ucPanel ucBtn-bg" style="text-align: right;">
            <button class="ucBtn ucBtn-ok ucBtn-blue" v-on:click="commit"><i class="iImg"></i>确定</button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    name: "um_role_permission_assign",
    data: function(){return{
            data:[],//树形数据
            defaultCheckedKeys:[],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
			id:this.$parent.id,
            rolePermDialogVisible:false
    }}, 
    created () {   
        this.rolePermDialogVisible = true;
        this.data = [];
        this.showPermissions();
    },
    methods: {
        showPermissions:function(){
           //获取角色数据
            var self = this;
            var data = {cmd: 'brum_permissionsTreeByRole'};
            data.roleId = self.id;
            this.$sknet.postJson(this.$skconf("brum.surl"), data,  function(data){
                var datatemp = data.tree;
                var tree = self.changedatTotree(datatemp,0);
                self.data = tree;
                //获取所有根节点
                this.getlastTag(tree);
                //只给根节点选中状态
                this.$refs.tree.setCheckedKeys(self.defaultCheckedKeys);
            }, function(data){
                console.log(data);
            }, self);
        },
        commit:function(){
            var self = this;
            var data = {cmd: 'brum_editRolePermissions'};
            data.roleId = self.id;
            data.permissionsId = [];
            var checkkeys = this.$refs.tree.getCheckedKeys();//全选中
            if(checkkeys == null || checkkeys.length == 0){
                self.$message("请选择需分配的权限");
                return;
            }
            var halfCheckKeys = this.$refs.tree.getHalfCheckedKeys();//半选中
            var nodes = checkkeys.concat(halfCheckKeys);
            for(var i = 0; i < nodes.length; i++){
                var code = nodes[i]+"";
                //code = code.substring(0,code.length-2);
                data.permissionsId.push(code);
            }
            this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data){
                data.reason;
                self.$message("分配权限成功");
				self.closeDialogrolePerm();
			}, function(data){
				if(data.reason == '该角色已删除'){
					self.$message(data.reason);
					self.closeDialogrolePerm();
				}else{
					self.$message("操作失败, " + (data.reason || "服务器端出错"));
				}
			}, self);
        },
        closeDialogrolePerm: function(){
            //this.clearinfo();
            this.rolePermDialogVisible = false;
            this.$emit('closeDialogrolePerm'); //通知父组件改变。
        },
        changedatTotree:function(data,parentId){
            var itemArr = [];
            for (var i = 0; i < data.length; i++) {
                var node = data[i];
                if (node.pid === parentId) {
                    var newNode = {id: node.id, label: node.name,checked:node.checked,pid:node.pid, children: this.changedatTotree(data, node.id)};
                    itemArr.push(newNode);
                }
            }
            return itemArr;
        },
        getlastTag:function(data){
            for (var i = 0; i < data.length; i++){
                var temp_data = data[i];
                if(temp_data.children && temp_data.children.length > 0){
                    this.getlastTag(temp_data.children);
                }else{
                    if(temp_data.checked){
                        this.defaultCheckedKeys.push(temp_data.id);
                    }
                }
            }
        },
        handleNodeClick:function(){

        }
        
    },
    mounted(){
        
    },
    components: {
        
    }

  }
</script>