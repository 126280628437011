<template>
    <div>
        <div class="ucPanel bgWhite radius" style="padding:0px 15px 5px; margin-bottom:15px;">
    <div class="ucForm ucForm-flow ucBtn-bg">
     
          <div class="ucForm-group">
             <label class="ucForm-label">名称</label>
             <input name="" type="text" placeholder="请输入名称" class="ucForm-control" v-model="name" />
          </div>
          <div class="ucForm-group frBtn"> 
              <button class="ucBtn ucBtn-search ucBtn-blue"  v-on:click="search"> <i class="iImg"></i>查询</button>
          </div>
          <div class="clear"></div>
    </div>
</div>

<div class="ucPanel bgGrayWhite" style="padding:10px;">
      <button class="ucBtn ucBtn-edit ucBtn-blue" v-on:click="updateItems"><i class="iImg"></i>批量修改 </button>
      <!-- <button class="ucBtn ucBtn-del ucBtn-blue" v-on:click="deleteItems"><i class="iImg"></i>批量删除</button> -->
      <button class="ucBtn ucBtn-add ucBtn-blue" v-on:click="addItem"><i class="iImg"></i>添加</button>
</div>

<div class="ucPanel bgWhite radius" style="padding:15px; border-top-left-radius: 0px; border-top-right-radius: 0px; ">     
    <div class="ucPanel ucPanel-table">
       <table width="100%" border="0" cellspacing="0" cellpadding="0" class="ucTable border">
                  <thead>
                      <tr>
                            <th width="20"><input type="checkbox" v-model="isCheckAll" /></th>
                            <th class="thId">ID</th>
							<th>名称</th>
							<th>描述</th>
							<th class="thBtn">操作</th>
                      </tr>
                  </thead>
                  <tbody>
						<tr v-for="item in items" v-bind:key="item.id">
							<td><input type="checkbox" v-model="item.check"/></td>
                            <td>{{item.id}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.description}}</td>
							<td class="tdBtn" style="width: 400px;">
								<button type="submit" class="ucBtn ucBtn-set ucBtn-blue ucbtn-icon" title="分配权限" v-on:click="assignpermissions(item)"><i class="iImg"></i>分配权限 </button>
								<button type="submit" class="ucBtn ucBtn-edit ucBtn-blue ucbtn-icon" title="编辑" v-on:click="editInfo(item)"><i class="iImg"></i>编辑 </button>
								<button type="submit" class="ucBtn ucBtn-see ucBtn-blue ucbtn-icon" title="查看" v-on:click="viewInfo(item)"><i class="iImg"></i>查看 </button>
								<!-- <button type="submit" class="ucBtn ucBtn-del ucBtn-blue ucbtn-icon" title="删除" v-on:click="deleteInfo(item)"><i class="iImg"></i>删除 </button> -->
							</td>
									
						</tr>
					</tbody>
					<tbody v-if="items.length == 0">
						<tr>
								<td colspan="8">暂无数据</td>
						</tr>
					</tbody>

        </table>
    </div>
<!--    <el-pagination
      :style="{visibility: tableVisible ? 'visible' : 'hidden'}"
      @size-change="handleSizeChange"
      @current-change="handlePageChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination> -->
	<el-pagination @size-change="handleSizeChange" @current-change="handlePageChange"
		v-model:currentPage="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper" :total="total">
	</el-pagination>
    
</div>
<um_role_info @closeDialogRole="closeDialogRole"  v-if="roleDialogVisible"></um_role_info>
<um_role_edit_role @closeDialogUpdateRoleitems="closeDialogUpdateRoleitems"  v-if="updateRoleitemsDialogVisible"></um_role_edit_role>
<um_role_permission_assign @closeDialogrolePerm="closeDialogrolePerm"  v-if="rolePermDialogVisible"></um_role_permission_assign>
    </div>
</template>
<script>

import um_role_info from './um_role_info.vue'
import um_role_edit_role from './um_role_edit_role.vue'
import um_role_permission_assign from './um_role_permission_assign.vue'


    export default {
        name: 'um_role_list',
        props: {
            msg: String
        },
        data: function(){return{
            name:"",//模糊搜索 名称
            //数据
            items:[],
            //全选按钮
            isCheckAll:false,
            //分页控制
            currentPage: 1,
            pageSize: 10,
            total: 0,
            checkItem:[],
            id:"",
            mod:"",
            roleDialogVisible:false,
            tableVisible: true,
            updateRoleitemsDialogVisible:false,
            rolePermDialogVisible:false
        }}, 
        created () {   
            this.search();
        },
        watch:{
            "isCheckAll":function(value){
                var self = this;
                if(value){
                    for(var i=0;i<self.items.length;i++){
                        self.items[i].check = true;
                    }
                }else{
                    for(var p=0;p<self.items.length;p++){
                        self.items[p].check = false;
                    }
                }
            }
        },
        methods: {
            handleSizeChange:function(val){
                this.pageSize = val
                this.search()
            },
            handlePageChange (val) {
                this.currentPage = val
                this.search()
            },
            search:function(){
                var self = this;
                var data = {cmd: "brum_searchRoles"};
                data.start = (this.currentPage-1) * this.pageSize;
                data.count = this.pageSize;
                data.name = this.name;
                this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data){
                    var items = data.items;
                    var result = [];
                    if(typeof items != "undefined"){
                        for(var i = 0; i < items.length; i ++){
                            result.push(self.convertBean(items[i]));
                        }
                    }
                    self.items = result;
                    self.total = data.totalCount;
                }, null, self);
            },
            convertBean:function(data){
                var obj = {};
                obj.check = false;//选中true
                obj.id = data.id||"";
                obj.name = data.name||"";
                obj.description = data.description||"";
                return obj;
            },
            closeDialogRole:function(){
                this.id = "";
                this.mod = "";
                this.roleDialogVisible = false;
                this.search();
            },
            updateItems: function(){
                this.checkItem = this.getCheckItem();
                this.updateRoleitemsDialogVisible = true;
            },
            deleteItems: function(){
                var self = this;
                this.$confirm('您确定要删除所选角色吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    self.realDeleteItems();
                }).catch(() => {
                    
                });
            },
            getCheckItem:function(){
                var items = [];
                for(var i =0;i< this.items.length;i++){
                    var check = this.items[i].check;
                    if(check){
                        items.push(this.items[i]);
                    }
                }
                return items;
            },
            realDeleteItems:function(){
                var self = this;
                //获取所有已选择的用户信息
                var data = {cmd: "brum_deleteRoleBatch"};
                var items = self.getCheckItem();
                if(typeof(items) == "undefined" || items.length == 0){
                    self.$message('没有选中的角色');
                    return;
                }
                var ids = new Array();
                for(var i = 0; i < items.length; i ++){
                    ids.push(items[i].id);
                }
                data.ids = ids; 
                this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data){
                    data.reason;
                    self.currentPage = 1;
                    self.search();
                }, null, self);
            },
            addItem: function(){
                this.id = "";
                this.mod = "add";
                this.roleDialogVisible = true;
            },
            assignpermissions: function(item){
                this.id = item.id+"";
                this.rolePermDialogVisible = true;
            },
            closeDialogrolePerm:function(){
                this.id = "";
                this.rolePermDialogVisible = false;
                this.search();
            },
            editInfo: function(item){
                this.id = item.id+"";
                this.mod = "edit";
                this.roleDialogVisible = true;
            },
            viewInfo: function(item){
                this.id = item.id+"";
                this.mod = "view";
                this.roleDialogVisible = true;
            },
            deleteInfo: function(item){
                var self = this;
                this.$confirm('您确定要删除该角色吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    var data = {cmd : 'brum_deleteRole'};
                    data.id = item.id;
                    this.$sknet.postJsonSync(this.$skconf("brum.surl"), data, function(data) {
                        data.reason;
                        self.currentPage = 1;
                        self.search();
                    }, null, self);
                }).catch(() => {
                    
                });

            },
            closeDialogUpdateRoleitems:function(){
                this.checkItem = [];
                this.updateRoleitemsDialogVisible = false;
                this.search();
            }
        },
        components: {
            um_role_info,
            um_role_edit_role,
            um_role_permission_assign
        },
        mounted: function(){
        }
    }
</script>