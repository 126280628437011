import { createApp } from 'vue'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import { createRouter, createWebHashHistory   } from 'vue-router'
import Sk from './sk-saas.js'
//import wfrm from "wfrm"
//import "wfrm/dist/wfrm.css"
//import brum from "br-um"
import brpd from "br-pd"

import echarts from 'echarts'
import 'uc/dist/uc.css'
import MyConfig from "./config.js"

//import antd from 'ant-design-vue'

import App from './App.vue'

import DesignForm from 'vue-form-create'

//import 'ant-design-vue/dist/antd.css'


import wfrm_frame from "./components/wfrm_frame.vue"
import wfrm_login from "./components/wfrm_login.vue"
import wfrm_menu_tree from "./components/wfrm_menu_tree.vue"
//import wfrm_frame_console from "./components/wfrm_frame_console.vue"

import um_user_list from './components/user/um_user_list.vue';
import um_role_list from './components/role/um_role_list.vue';
import um_group_list from './components/group/um_group_list.vue';
import sec_performance_list from './components/per/sec_performance_list.vue';
import rms_performance_list from './components/per/rms_performance_list.vue';

import '@/assets/myStyle.css'

import dialogDrag from '@/js/dialogDrag.js'

const components = [
  wfrm_frame,
  wfrm_login,
  wfrm_menu_tree,
  um_user_list,
  um_group_list,
  um_role_list,
  // sec_performance_list,

  //wfrm_frame_console,
]
const app = createApp(App);
const router = createRouter({
    history: createWebHashHistory(),
    routes: []
})
components.forEach(component => {
    app.component(component.name, component);
});



/**
 * 合同管理三个页面
 */
app.component("ctms_c_list", () => import('./components/ctms/ctms_contract_list.vue'));
app.component("ctms_c_jk", () => import('./components/ctms/ctms_contract_search_list.vue'));
app.component("ctms_c_lr", () => import('./components/ctms/ctms_contract_input_list.vue'));
app.component("ctms_c_bm", () => import('./components/ctms/ctms_department_contract_list.vue'));
app.component("ctms_c_lrxx", () => import('./components/ctms/ctms_contract_money_list.vue'));
app.component("ctms_c_xzqk", () => import('./components/ctms/ctms_contract_money_info.vue'));
app.component("ctms_c_progress", () => import('./components/ctms/ctms_contract_progress_list.vue'));

/**
 * 报维修管理系统页面写在下方
 */
app.component("rms_c_members", () => import('./components/rms/rms_c_members_list.vue'));
app.component("um_class", () => import('./components/class/um_class_list.vue'));
app.component("um_performance_list", () => import('./components/per/um_performance_list.vue'));
app.component("sec_performance_list", () => import('./components/per/sec_performance_list.vue'));
app.component("rep_repairs_list", () => import('./components/repa/rep_repairs_list.vue'));
app.component("rms_material_type_list", () => import('./components/rms/rms_material_type_list.vue'));
app.component("rms_bonus_list", () => import('./components/rms/rms_bonus_list.vue'));
app.component("rms_performance_list", () => import('./components/per/rms_performance_list.vue'));
app.component("rms_dept_list", () => import('./components/per/rms_dept_list.vue'));
app.component("rms_evaluate_list", () => import('./components/per/rms_evaluate_list.vue'));
app.component("rms_material_list", () => import('./components/rms/rms_material_list.vue'));
app.component("rms_report_list", () => import('./components/rms/rms_report_list.vue'));
app.component("rms_evaluate_infolist", () => import('./components/per/rms_evaluate_infolist.vue'));
app.component("rms_home_page", () => import('./components/rms/rms_home_page_list.vue'));
app.component("rms_data_list", () => import('./components/rms/rms_data_list.vue'));
app.component("rms_dictionary_list", () => import('./components/rms/rms_dictionary_list.vue'));
app.component("banner_page", () => import('./components/per/banner_page_list.vue'));

app.component("rms_thing_page", () => import('./components/repa/rms_thing_list.vue'));
app.component("rms_thing_total", () => import('./components/rms/rms_thing_total.vue'));
app.component("rms_material_finance_list", () => import('./components/rms/rms_material_finance_list.vue'));
//app.use(wfrm);
app.use(brpd);
//app.use(brum);
app.use(router)
app.use(Sk);
app.use(MyConfig);
app.use(ElementPlus,{locale});
//app.echarts=echarts
//app.use(antd)
app.use(DesignForm);
app.use(dialogDrag);
app.mount('#app')

