<template>
	<el-dialog title="修改密码" v-model="centerDialogVisible" width="40%" center append-to-body @close='closepswinfo'>
		<div class="ucForm ucForm-lump auto-d1 nameLong">
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label"><span class="fontRed">*</span>旧密码</label>
					<input name="oldpassword" type="password" v-model="oldpassword" :disabled="viewOnly" class="ucForm-control">
				</div>
			</div>
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label"><span class="fontRed">*</span>新密码</label>
					<input name="newpassword" type="password" v-model="newpassword" :disabled="viewOnly" class="ucForm-control">
				</div>
			</div>
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label"><span class="fontRed">*</span>重复新密码</label>
					<input name="confirmpassword" type="password" v-model="confirmpassword" :disabled="viewOnly" class="ucForm-control">
				</div>
			</div>
			<div v-if="viewOnly == false" class="ucForm-group-ad ucForm-group-btn auto-d100">
				<div class="ucForm-group ucBtn-bg">
					<button class="ucBtn ucBtn-ok ucBtn-blue" v-on:click="commit"><i class="iImg"></i>确定</button>
					<button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closepswinfo"><i class="iImg"></i>关闭</button>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		name: "psw_info",
		data: function() {
			return {
				oldpassword: "",
				newpassword: "",
				confirmpassword: "",
				viewOnly: false,
				centerDialogVisible: false
			}
		},
		created() {
			this.centerDialogVisible = true;
		},
		methods: {
			commit: function() {
				var self = this;
				if(typeof self.oldpassword == "undefined" || self.oldpassword == ""){
					self.$message("请填写旧密码!");
					return;
				}
				
				if(typeof self.newpassword == "undefined" || self.newpassword == ""){
					self.$message("请填写新密码!");
					return;
				}
				
				if(typeof self.confirmpassword == "undefined" || self.confirmpassword == ""){
					self.$message("请填写重复新密码!");
					return;
				}
				
				
				if(self.newpassword != self.confirmpassword){
					self.$message("新密码不匹配，请重新输入!");
					return;
				}
				
				if(self.oldpassword == self.newpassword){
					self.$message("新密码与旧密码不能相同!");
					return;
				}
				
				var data = {cmd: 'brum_modifyPassword', oldPassword: self.oldpassword, newPassword: self.newpassword};
				self.$sknet.postJson(this.$skconf("brum.surl"), data, function(data) {
					self.$alert('密码修改成功，请重新登录系统', '提示', {
						confirmButtonText: '确定',
						callback: action => {
							//清除cookie
							self.cleanCookiePassword();
							self.closepswinfo();
							//调用父级的logout();
							self.$parent.logout();
						}
					});
				}, null, self);
			},
			closepswinfo: function() {
				this.centerDialogVisible = true;
				this.clearinfo();
				this.$emit('closepswinfo'); //通知父组件改变。
			},
			clearinfo: function() {
				this.oldpassword = "";
				this.newpassword = "";
				this.confirmpassword = "";
			},
			cleanCookiePassword() {
				var exdate = new Date(); //获取时间
				exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * 7); //保存的天数
				//字符串拼接cookie
				window.document.cookie =
					"userPwd" + "=" + "" + ";path=/;expires=" + exdate.toGMTString();
			}
		},
		mounted() {
			
		},
		components: {
			
		}
	}
</script>

<style scoped>

</style>
