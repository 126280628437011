<template>
  <el-dialog
  title="批量修改"
  v-model="updateRoleitemsDialogVisible"
  width="30%"
  center append-to-body @close='closeDialogUpdateRoleitems'>

<div class="ucForm ucForm-lump auto-d1"> 
      <div class="ucForm-group-ad">
          <div class="ucForm-group">
             <label class="ucForm-label">角色名称</label>
             <input class="ucForm-control" v-model="name" type="text">
          </div>
      </div>
      <div class="ucForm-group-ad">
          <div class="ucForm-group">
             <label class="ucForm-label">角色描述</label>
             <input class="ucForm-control" v-model="description" type="text">
          </div>
      </div>
      <div class="ucForm-group-ad ucForm-group-btn auto-d100">
          <div class="ucForm-group ucBtn-bg">
             <button class="ucBtn ucBtn-ok ucBtn-blue ucBtn-wSmall" v-on:click="commit"><i class="iImg"></i>确定</button>
             <button class="ucBtn ucBtn-refresh ucBtn-gray ucBtn-wSmall" v-on:click="closeDialogUpdateRoleitems"><i class="iImg"></i>关闭</button>
          </div>
      </div>
      <div class="clear"></div>
</div>
</el-dialog>
</template>

<script>
  export default {
    name: "um_role_edit_role",
    data: function(){return{
            name:"",
            description:"",
            updateRoleitemsDialogVisible:false,
            checkItem:this.$parent.checkItem
    }}, 
    created () {   
        this.updateRoleitemsDialogVisible = true,
        this.clearinfo();
    },
    methods: {
        commit:function(){
            var self = this;
            var data = {};
            data.cmd = "brum_editRoleBatch";
            let items = self.checkItem;
			if(items.length <= 0){
        self.$message("请添加批量修改的选项！");
				return;
			}else {
        var ids = new Array();
				for(var i = 0; i < items.length; i ++){
					ids.push(items[i].id);
				}
				data.ids = ids;
			}
            data.name = self.name||"";
            data.description = self.description||"";
			this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data){
                data.reason;
                self.$message("修改成功");
				self.closeDialogUpdateRoleitems();
			}, function(data) {
				self.$message(data.reason);
			}, self);
        },
        closeDialogUpdateRoleitems: function(){
            this.clearinfo();
            this.$emit('closeDialogUpdateRoleitems'); //通知父组件改变。
        },
        clearinfo:function(){
            this.name = "";
            this.description = "";
        }
    },
    mounted(){
        
    },
    components: {
        
    }
  }
</script>

<style scoped>

</style>