<template>
    <div>
        <div class="ucPanel bgWhite radius" style="padding:0px 15px 5px; margin-bottom:15px;">
    <div class="ucForm ucForm-flow ucBtn-bg">
          <div class="ucForm-group">
             <label class="ucForm-label">组织名称</label>
             <input name="" type="text" placeholder="请输入组织名称" class="ucForm-control" v-model="sgname" />
          </div>
          <div class="ucForm-group">
             <label class="ucForm-label">用户名/姓名</label>
             <input name="" type="text"  placeholder="请输入用户名/姓名" class="ucForm-control" v-model="userName" />
          </div>
          <div class="ucForm-group frBtn"> 
              <button class="ucBtn ucBtn-search ucBtn-blue"  v-on:click="search"> <i class="iImg"></i>查询</button>
          </div>
          <div class="clear"></div>
    </div>
</div>

<div class="ucPanel bgGrayWhite" style="padding:10px;">
      <button class="ucBtn ucBtn-edit ucBtn-blue" v-on:click="updateItems"><i class="iImg"></i>批量修改 </button>
      <button class="ucBtn ucBtn-del ucBtn-blue" v-on:click="deleteItems"><i class="iImg"></i>批量删除</button>
      <button class="ucBtn ucBtn-add ucBtn-blue" v-on:click="addItem"><i class="iImg"></i>添加</button>
      <button class="ucBtn ucBtn-import ucBtn-blue" v-on:click="go"><i class="iImg"></i>导入</button>
      <input  style="display:none;" ref="upload_user_file" type="file" id="upload_user_file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadUser($event)" name="file1">
      <button class="ucBtn ucBtn-download ucBtn-blue" v-on:click="uploadModel"><i class="iImg"></i>模板下载 </button>
</div>

<div class="ucPanel bgWhite radius" style="padding:15px; border-top-left-radius: 0px; border-top-right-radius: 0px; ">     
    <div class="ucPanel ucPanel-table">
       <table width="100%" border="0" cellspacing="0" cellpadding="0" class="ucTable border">
                  <thead>
                      <tr>
                            <th width="20"><input type="checkbox" v-model="isCheckAll" /></th>
                            <th class="thId">ID</th>
							<th>用户名</th>
							<th>姓名</th>
							<th>性别</th>
							<th>组织名称</th>
							<th>角色</th>
							<th class="thBtn">操作</th>
                      </tr>
                  </thead>
                  <tbody>
						<tr v-for="item in items" v-bind:key="item.id">
							<td><input type="checkbox" v-model="item.check"/></td>
                            <td>{{item.id}}</td>
							<td>{{item.username}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.gender}}</td>
                            <td>{{item.organization}}</td>
                            <td>{{item.role}}</td>
							<td class="tdBtn" style="width: 400px;">
								<button type="submit" class="ucBtn ucBtn-adList ucBtn-blue ucbtn-icon" title="分配组织与角色" v-on:click="assignRole(item)"><i class="iImg"></i>分配组织与角色 </button>
								<button type="submit" class="ucBtn ucBtn-edit ucBtn-blue ucbtn-icon" title="编辑" v-on:click="editInfo(item)"><i class="iImg"></i>编辑 </button>
								<button type="submit" class="ucBtn ucBtn-see ucBtn-blue ucbtn-icon" title="查看" v-on:click="viewInfo(item)"><i class="iImg"></i>查看 </button>
								<button type="submit" class="ucBtn ucBtn-del ucBtn-blue ucbtn-icon" title="删除" v-on:click="deleteInfo(item)"><i class="iImg"></i>删除 </button>
							</td>
									
						</tr>
					</tbody>
					<tbody v-if="items.length == 0">
						<tr>
								<td colspan="8">暂无数据</td>
						</tr>
					</tbody>

        </table>
    </div> 
	<el-pagination @size-change="handleSizeChange" @current-change="handlePageChange"
		v-model:currentPage="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper" :total="total">
	</el-pagination>
</div>
    <um_user_info @closeuserinfo="closeuserinfo"  v-if="centerDialogVisible"></um_user_info>
    <um_user_update @closeDialogUpdateitems="closeDialogUpdateitems"  v-if="updateitemsDialogVisible"></um_user_update>
</div>
</template>
<script>
    import um_user_info from './um_user_info.vue'
    import um_user_update from './um_user_update.vue'
    export default {
        name: 'um_user_list',
        props: {
            msg: String
        },
        data: function(){return{
            groupList:[],
            userName:"",							//模糊搜索 用户名、姓名
            group:0,
            sgname:"",
            //数据
            items:[],
            //全选按钮
            isCheckAll:false,
            //分页控制
            currentPage: 1,
            pageSize: 10,
            total: 0,
            checkItem:[],
            check:"",
            tableVisible: true,
            modalVisible:false,
            modalObj:{"title":"123123"},
            centerDialogVisible:false,
            updateitemsDialogVisible:false,
            mod:"",
            id:""
        }}, 
        created () {   
            this.search();
        },
        watch:{
            "isCheckAll":function(value){
                var self = this;
                if(value){
                    for(var i=0;i<self.items.length;i++){
                        self.items[i].check = true;
                    }
                }else{
                    for(var p=0;p<self.items.length;p++){
                        self.items[p].check = false;
                    }
                }
            }
        },
        methods: {
            handleSizeChange:function(val){
                this.pageSize = val
                this.search()
            },
            handlePageChange (val) {
                this.currentPage = val
                this.search()
            },
            search:function(){
                var self = this;
                var data = {cmd: "brum_searchUsers"};
                data.start = (this.currentPage-1) * this.pageSize;
                data.count = this.pageSize;
                data.keywords = this.userName;
                data.groupName = this.sgname;
                this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data){
                    var items = data.items;
                    var result = [];
                    if(typeof items != "undefined"){
                        for(var i = 0; i < items.length; i ++){
                            result.push(self.convertBean(items[i]));
                        }
                    }
                    self.items = result;
                    self.total = data.totalCount;
                }, null, self);
            },
            convertBean:function(data){
                var obj = {};
                obj.check = false;//选中true
                obj.id = data.id||"";
                obj.username = data.username||"";
                obj.name = data.name||"";
                obj.gender = data.gender == 1 ? "女" : "男";
                obj.organization = data.groupNames||"";
                obj.role = data.roleNames||"";
                return obj;
            },
            updateItems: function(){
                this.checkItem = this.getCheckItem();
                this.updateitemsDialogVisible = true;
            },
            getCheckItem:function(){
                var items = [];
                for(var i =0;i< this.items.length;i++){
                    var check = this.items[i].check;
                    if(check){
                        items.push(this.items[i]);
                    }
                }
                return items;
            },
            deleteItems:function(){
                var self = this;
                this.$confirm('您确定要删除所选用户吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    self.realDeleteItems();
                }).catch(() => {
                    
                });
            },
            realDeleteItems: function(){
                var self = this;
                //获取所有已选择的用户信息
                var data = {cmd: "brum_deleteUserBatch"};
                var items = self.getCheckItem();
                if(typeof(items) == "undefined" || items.length == 0){
                    self.$message('没有选中的用户');
                    return;
                }
                
                var ids = new Array();
                for(var i = 0; i < items.length; i ++){
                    ids.push(items[i].id);
                }
                data.ids = ids; 

                this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data){
                    data.reason;
                    self.currentPage = 1;
                    self.search();
                }, null, self);

            },
            addItem: function(){
                this.id = '';
                this.mod = 'add';
                this.centerDialogVisible = true;
            },
            closeuserinfo:function(){
                this.id = '';
                this.mod = '';
                this.centerDialogVisible = false;
                this.search();
            },
            closeDialogUpdateitems:function(){
                //this.checkItem = [];
                this.updateitemsDialogVisible = false;
                this.search();
            },
            go: function(){
                this.$refs.upload_user_file.click()
            },
            uploadModel: function(){
                window.open(this.$skconf("brum.downusertemp"));
            },
            assignRole: function(item){
                this.id = item.id+"";
                this.mod = 'assign';
                this.centerDialogVisible = true;
            },
            editInfo: function(item){
                this.id = item.id+"";
                this.mod = 'edit';
                this.centerDialogVisible = true;
            },
            viewInfo: function(item){
                this.id = item.id+"";
                this.mod = 'view';
                this.centerDialogVisible = true;
            },
            deleteInfo: function(item){
                var self = this;
                this.$confirm('您确定要删除该用户吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    var data = {cmd : 'brum_deleteUser'};
                    data.id = item.id;
                    this.$sknet.postJsonSync(this.$skconf("brum.surl"), data, function(data) {
                        data.reason;
                        self.currentPage = 1;
                        self.search();
                    }, null, self);
                }).catch(() => {
                    
                });
            },
            importUsers:function(file){
                var self = this;
                var data = {cmd: "brum_importUsers"}
                data.file=file;            //excel oss路径
                this.$sknet.postJsonSync(this.$skconf("brum.surl"), data, function(data){
                    if(typeof data.datas.items!= "undefined" && data.datas.items!=""&&data.datas.items!=null&&data.datas.items.length>0){
                        self.$message("导入用户信息中包含重复信息");
                        //打开弹窗
                        //var items=data.datas.items;
                        //webfrm.dlg.openWithComponent({title: "重复用户信息",width:"800",height:"800"},"copy_user_list", {cf: self.search, context: this, items: items, mod: "edit"});
                    }
                    self.$message(data.reason);
                    self.search();
                }, null, self);
            },
            uploadUser:function(){
                var self = this;
                var url = this.$skconf("brum.oss.uurl");
                var bucket = this.$skconf("brum.oss.bucket");
                this.$sknet.ossUploadFile(url, bucket, ['upload_user_file'], function(res){
                    var file = self.$skconf("brum.oss.vurl") + "" + res.files[0].id;
                    self.importUsers(file);
                }, null,null);
            }

            
        },
        components: {
            um_user_info,
            um_user_update
        },
        mounted: function(){
        }
    }
</script>