<template>
  <el-form ref="searchForm"   label-width="240" :inline="true">
	
  </el-form>

  <el-table :data="dataList" row-key="id" border  ref="multipleTable">

	<el-table-column prop="name" label="绩效名称" ></el-table-column>
    <el-table-column prop="grabb_money" label="抢单金额" ></el-table-column>
    <el-table-column prop="distr_money" label="派单金额"></el-table-column>
    <el-table-column prop="status" label="绩效状态" :formatter="forstusta"></el-table-column>
  
  </el-table>
 
</template>
<script>
  //中文包
  import zhCn from "element-plus/lib/locale/lang/zh-cn";
  export default {
    name: 'ZhProvider',
    setup(){
      let locale = zhCn
      return{
        locale
      }
    },
	data() {
	  let self=this;
	  return {
	    dataList:[],  //表格数据集
	  }
	},

  /*启动后触发该方法*/
  created() {
	this.queryMembers()
  },
  methods: {
    queryMembers:function(){
		this.dataList = [];
		var req = {};
		req.cmd = "getSecPerformanceList";
		this.$sknet.postJson(this.$skconf("cla.surl"), req, function(res) {
		  this.dataList=res.datas.items;
		}, null, this);
    },
	forstusta:function(row,colum){
	  const state={
	    0:'禁用',
	    1:'开启',
	  }
	  return  state[row.status];
	}
  },

  components: {
  },
}
</script>