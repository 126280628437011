<template>
	<el-dialog title="人员信息" v-model="centerDialogVisible" width="70%" center append-to-body @close='closeDialog'>
		<div class="ucForm ucForm-lump auto-d2">
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">用户名</label><input name="username" type="text" v-model="username"
						:disabled="viewOnly" class="ucForm-control">
				</div>
			</div>
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">密码</label><input class="ucForm-control" v-model="password"
						:disabled="viewOnly" type="password">
				</div>
			</div>
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">姓名</label><input class="ucForm-control" v-model="name"
						:disabled="viewOnly" type="text">
				</div>
			</div>
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">性别</label>
					<select class="ucForm-control" name="public-choice" v-model="gender" :disabled="viewOnly">
						<option :value="coupon.id" v-for="coupon in genders" v-bind:key="coupon.id">{{coupon.name}}
						</option>
					</select>
				</div>
			</div>
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">管理岗位</label>
					<select class="ucForm-control" name="public-choice" v-model="gwid" :disabled="viewOnly">
						<option :value="coupon.id" v-for="coupon in gwList" v-bind:key="coupon.id">{{coupon.name}}
						</option>
					</select>
				</div>
			</div>
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">手机号</label><input class="ucForm-control" v-model="telephone"
						:disabled="viewOnly" type="text">
				</div>
			</div>
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">邮箱</label> <input class="ucForm-control" v-model="email"
						:disabled="viewOnly" type="text">
				</div>
			</div>
			<div class="ucForm-group-ad">
				<div class="ucForm-group">
					<label class="ucForm-label">生日</label> <input class="ucForm-control" v-model="birthdate"
						:disabled="viewOnly" type="text">
				</div>
			</div>

			<div class="ucForm-group-ad auto-d100">
				<div class="ucForm-group">
					<label class="ucForm-label">住址</label> <input class="ucForm-control" v-model="address"
						:disabled="viewOnly" type="text">
				</div>
			</div>
			<div v-if="viewOnly == false" class="ucForm-group-ad ucForm-group-btn auto-d100">
				<div class="ucForm-group ucBtn-bg">
					<button class="ucBtn ucBtn-ok ucBtn-blue" v-on:click="commit"><i class="iImg"></i>确定</button>
					<button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog"><i
							class="iImg"></i>关闭</button>
				</div>
			</div>
			<div class="clear"></div>
		</div>
		<div v-show="mod == 'assign'" class="ucPanel " style="border-top: 1px #dcdcdc solid; padding:10px 0px;">
			<div class="ucPanel-title">
				<div class="fl">
					<div class="fl flName" style=" margin-right: 10px;">分配组织</div>
					<div class="fl" style=" margin-right: 10px;">
						<select class="ucForm-control" name="public-choice" v-model="group">
							<option :value="coupon.id" v-for="coupon in groups" v-bind:key="coupon.id">{{coupon.name}}
							</option>
						</select>
					</div>
					<div class="fl ucBtn-bg">
						<button type="submit" class="ucBtn ucBtn-add ucBtn-blue ucBtn-wSmall" title="添加组织"
							v-on:click="addUserGroup"><i class="iImg"></i>添加组织</button>
					</div>
				</div>
				<div class="clear"></div>
			</div>
			<div class="ucPanel ucBtn-bg" id="chooseGroup" style="padding:5px 0px 5px 65px;">
				<span :value="coupon.id" v-for="coupon in orgItems" v-bind:key="coupon.id"
					class="ucBtn ucBtn-see ucBtn-pri"><span>{{ coupon.name }}</span><i class="curP fontRed"
						v-on:click="delUserGroup(coupon)" title="删除" style="padding-left: 5px;">x</i></span>
			</div>
		</div>
		<div v-show="mod == 'assign'" class="ucPanel" style="border-top: 1px #dcdcdc solid; padding:10px 0px;">
			<div class="ucPanel-title">
				<div class="fl flName">分配角色</div>
				<div class="fr frBtn">
					<button type="submit" class="ucBtn ucBtn-add ucBtn-blue ucBtn-icon" title="添加"
						v-on:click="addUserGroupRole"><i class="iImg"></i></button>
				</div>
				<div class="clear"></div>
			</div>
			<div class="ucPanel ucPanel-table" style="max-height: 149px;">
				<table width="100%" border="0" cellspacing="0" cellpadding="0" class="ucTable border">
					<thead>
						<tr>
							<th>组织机构</th>
							<th>角色名称</th>
							<th>操作</th>
						</tr>
					</thead>
					<tbody>
						<tr :value="coupon.id" v-for="coupon in ugr" v-bind:key="coupon.id">
							<td>{{ coupon.groupName }}</td>
							<td>{{ coupon.roleName }}</td>
							<td width="100">
								<button type="submit" class="ucBtn ucBtn-del ucBtn-blue ucbtn-icon" title="删除"
									v-on:click="deleteUserGroupRole(coupon)"><i class="iImg"></i></button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- <um_usergrouprole_add @closegrouprole="closegrouprole" v-bind:parentmsg="id" v-if="grouproleDialogVisible">
		</um_usergrouprole_add>
		<el-button type="text" @click="centerDialogVisible1 = true">点击打开 Dialog</el-button> -->

		<el-dialog title="添加用户角色" v-model="grouproleDialogVisible" width="30%" center @close='closeDialogRole'>
			<div class="ucForm ucForm-lump auto-d1">
				<div class="ucForm-group-ad">
					<div class="ucForm-group">
						<label class="ucForm-label">组织</label>
						<select class="ucForm-control" name="public-choice" v-model="groupadd">
							<option :value="coupon.id" v-for="coupon in groups" v-bind:key="coupon.id">{{coupon.name}}
							</option>
						</select>
					</div>
				</div>
				<div class="ucForm-group-ad">
					<div class="ucForm-group">
						<label class="ucForm-label">角色</label>
						<select class="ucForm-control" name="public-choice" v-model="role">
							<option :value="coupon.id" v-for="coupon in roles" v-bind:key="coupon.id">{{coupon.name}}
							</option>
						</select>
					</div>
				</div>
			
				<div class="ucForm-group-ad ucForm-group-btn auto-d100">
					<div class="ucForm-group ucBtn-bg">
						<button class="ucBtn ucBtn-ok ucBtn-blue ucBtn-wSmall" v-on:click="commitrole"><i
								class="iImg"></i>确定</button>
					</div>
				</div>
			
				<div class="clear"></div>
			</div>
		</el-dialog>
	</el-dialog>
</template>

<script>
	export default {
		name: "um_user_info",
		data: function() {
			return {
				genders: [{
					id: 1,
					name: '男'
				}, {
					id: 2,
					name: '女'
				}],
				ugr: [],
				gwList: [{
					id: 1,
					name: '否'
				}, {
					id: 2,
					name: '是'
				}],
				groups: [],
				orgItems: [],
				username: "", //模糊搜索 用户名、姓名
				password: "",
				name: "",
				gender: "",
				telephone: "",
				email: "",
				birthdate: "",
				address: "",
				gwid: "",
				group: "",
				groupadd: "",
				viewOnly: false,
				groupArray: [],
				userId: "",
				grouproleDialogVisible: false,
				centerDialogVisible: false,
				centerDialogVisible1: false,
				mod: this.$parent.mod,
				id: this.$parent.id,
				roles: [],
				role: "",
			}
		},
		created() {
			this.centerDialogVisible = true;
		},
		methods: {
			fetchGroup: function() {
				var self = this;
				var data = {
					cmd: "brum_chooseGroupsTree"
				};
				this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data) {
					if (data.items && data.items.length > 0) {
						self.formatGroups(data.items, 0);
						//self.group((self.item && self.item.parentId !=0) ? self.item.parentId : undefined);
					}
				}, null, self);
			},
			formatGroups(items, level) {
				var tmp = null;
				var preTreeNodeName = this.getTreeLevel(level);
				for (var i = 0; i < items.length; i++) {
					tmp = items[i];
					this.groupArray.push(tmp);
					tmp.name = preTreeNodeName + tmp.name + "(" + tmp.typeName + ")";
					this.groups.push(tmp);
					if (tmp.subGroups && tmp.subGroups.length > 0) {
						this.formatGroups(tmp.subGroups, ++level);
					}
				}
			},
			getGroupName(groupId) {
				var groupName = "";
				for (var i = 0; i < this.groupArray.length; i++) {
					if (this.groupArray[i].id == groupId) {
						groupName = this.groupArray[i].name;
						break;
					}
				}
				return groupName;
			},
			haveGroup(groupId) {
				var flag = true;
				for (var i = 0; i < this.orgItems.length; i++) {
					if (this.orgItems[i].id == groupId) {
						flag = false;
						break;
					}
				}
				return flag;
			},
			getTreeLevel: function(level) {
				var namePre = "";
				while (level > 0) {
					namePre += "|—";
					level--;
				}
				return namePre;
			},
			commit: function() {
				var self = this;
				var data = {};
				if (this.id > 0) {
					data.cmd = "brum_editUser";
					data.id = this.id;
				} else {
					data.cmd = "brum_addUser";
				}
				data.username = self.username || "";
				data.password = self.password || "";
				data.name = self.name || "";
				data.gender = self.gender || "";
				data.gwid = self.gwid;
				data.telephone = self.telephone || "";
				data.email = self.email || "";
				data.birthdate = self.birthdate || "";
				data.address = self.address || "";
				data.createdTime = '';
				data.createdBy = '';
				data.lastModifiedTime = '';
				data.lastModifiedBy = '';
				data.mobile = '';
				data.professionalTitle = '';
				data.positionalTitle = '';
				data.jobTitle = '';
				data.startJobTime = '';
				data.joinCompanyTime = '';
				data.stature = '';
				data.memo = '';
				data.extinfo1 = '';
				data.extinfo2 = '';
				data.extinfo3 = '';
				this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data) {
					data.reason;
					self.$message("操作成功");
					self.closeDialog();
					//调用父级的search();
					self.$parent.search();
				}, function(data) {
					if (data.reason && data.reason == "用户名已存在") {
						self.$message(data.reason);
						// $("[name=username]").focus();
						// $("[name=username]").css("border-color","red");
					}
				}, self);
			},
			closeDialog: function() {
				this.centerDialogVisible = true;
				this.clearinfo();
				this.$emit('closeuserinfo'); //通知父组件改变。
			},
			closegrouprole: function() {
				this.userId = "";
				this.grouproleDialogVisible = false;
				this.searchUgr();
			},
			opengrouprole: function() {
				this.userId = this.id + "";
				this.grouproleDialogVisible = true;
				this.clearroleinfo();
			},
			fetch: function() {
				var self = this;
				var data = {
					cmd: 'brum_getUserById',
					id: self.id
				};
				this.$sknet.postJsonSync(this.$skconf("brum.surl"), data, function(data) {
					var item = data.user;
					self.id = item.id;
					self.username = item.username;
					self.password = item.password;
					self.name = item.name;
					self.gender = item.gender;
					self.gwid = item.gwid;
					self.telephone = item.telephone;
					self.email = item.email;
					self.birthdate = item.birthdate;
					self.address = item.address;

					//初始化组织信息
					var org_arr = data.groups;
					var new_org_arr = [];
					for (var i = 0; i < org_arr.length; i++) {
						if (org_arr[i].groupId == 0) {
							new_org_arr.push({
								id: org_arr[i].groupId,
								name: '所有部门'
							});
						} else {
							var groupName = org_arr[i].groupName;
							if (org_arr[i].officeId > 0) {
								groupName += "(" + org_arr[i].officeName + ")";
							}
							new_org_arr.push({
								id: org_arr[i].groupId,
								name: groupName
							});
						}

					}
					self.orgItems = new_org_arr;
				}, null, self);
			},
			searchUgr: function() {
				var self = this;
				var data = {
					cmd: 'brum_searchUserGroupRoles',
					id: self.id
				};
				this.$sknet.postJsonSync(this.$skconf("brum.surl"), data, function(data) {
					var result = [];
					var items = data.roles;
					if (typeof items != "undefined") {
						for (var i = 0; i < items.length; i++) {
							result.push(self.convertUgr(items[i]));
						}
					}
					self.ugr = result;
				}, null, self);
			},
			convertUgr: function(data) {
				var obj = {};
				obj.groupId = data.groupId || 0;
				obj.groupName = data.groupId == 0 ? '所有部门' : data.groupName;
				obj.roleId = data.roleId;
				obj.roleName = data.roleName || "";
				return obj;
			},
			addUserGroupRole: function() {
				//添加角色
				this.opengrouprole();
			},
			addUserGroup: function() {
				var self = this;
				//获取当前已选择的机构
				if (self.group == null) {
					self.$message('请选择机构！');
					return;
				}
				var id = self.group;
				var name = self.getGroupName(self.group);
				var user_id = self.id;
				var flag = self.haveGroup(self.group);
				if (flag) {
					var data = {
						cmd: 'brum_addUserGroup'
					};
					data.userId = id;
					data.groupId = user_id;
					this.$sknet.postJsonSync(this.$skconf("brum.surl"), data, function(data) {
						data.reason;
						name = name.replace(/-/g, "");
						var obj = {
							name: name,
							id: id
						};
						var m = self.orgItems;
						m.push(obj);
						self.orgItems = m;
					}, null, self);
				} else {
					self.$message('不能选择重复的机构！');
				}
			},
			clearinfo: function() {
				this.ugr = [];
				this.groups = [];
				this.orgItems = [];
				this.username = ""; //模糊搜索 用户名、姓名
				this.password = "";
				this.name = "";
				this.gender = "";
				this.telephone = "";
				this.email = "";
				this.birthdate = "";
				this.address = "";
				this.gwid = "";
				this.group = "";
				this.groupArray = {};
			},
			delUserGroup: function(item) {

				var self = this;

				this.$confirm('您确定要删除该组织吗？删除后该组织下的所有角色权限、职位将同步删除。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var data = {
						cmd: 'brum_deleteUserGroup',
						userId: self.id
					};
					data.groupId = item.id;
					this.$sknet.postJsonSync(this.$skconf("brum.surl"), data, function(data) {
						data.reason;
						var org_arr = self.orgItems;
						var new_org_arr = [];
						for (var i = 0; i < org_arr.length; i++) {
							if (org_arr[i].id == item.id) {
								item.id
							} else {
								new_org_arr.push(org_arr[i]);
							}
						}
						self.orgItems = new_org_arr;
						self.searchUgr();
						//self.getUgp();
					}, null, self);
				}).catch(() => {

				});
			},
			searchRole: function() {
				var self = this;
				var data = {
					cmd: "brum_searchRoles"
				};
				this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data) {
					var items = data.items;
					var result = [];
					if (typeof items != "undefined") {
						for (var i = 0; i < items.length; i++) {
							result.push(self.convertRoleBean(items[i]));
						}
					}
					self.roles = result;
				}, null, self);
			},
			convertRoleBean: function(data) {
				var obj = {};
				obj.id = data.id || "";
				obj.name = data.name || "";
				return obj;
			},
			commitrole: function() {
				var self = this;
				var data = {
					cmd: "brum_addUserGroupRole"
				};
				data.userId = self.userId;
				data.groupId = self.groupadd;
				data.roleId = self.role;
				if (!self.role || self.role < 1) {
					self.$message("请选择角色");
					return;
				}
				this.$sknet.postJson(this.$skconf("brum.surl"), data, function(data) {
					data.reason;
					self.$message("添加成功");
					self.closeDialogRole();
					self.searchUgr();
				}, function(data) {
					self.$message(data.reason);
				}, self);
			},
			closeDialogRole: function() {
				this.grouproleDialogVisible = false;
				this.clearroleinfo();
			},
			clearroleinfo: function() {
				this.groupadd = "";
				this.role = "";
			},
			deleteUserGroupRole: function(item) {
				var self = this;
				this.$confirm('您确定要删除该角色吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var data = {
						cmd: 'brum_deleteUserGroupRole',
						userId: self.id
					};
					data.roleId = item.roleId;
					data.groupId = item.groupId;
					this.$sknet.postJsonSync(this.$skconf("brum.surl"), data, function(data) {
						data.reason;
						self.searchUgr();
					}, null, self);
				}).catch(() => {

				});
			}
		},
		mounted() {
			// this.reset();
			this.fetchGroup();
			this.searchRole();
			//设置 viewOnly
			if (this.mod == 'edit') {
				this.viewOnly = false;
				this.fetch();
			}
			if (this.mod == 'view') {
				this.viewOnly = true;
				this.fetch();

			}
			if (this.mod == 'assign') {
				this.viewOnly = true;
				this.fetch();
				this.searchUgr();
			}
			if (this.mod == 'add') {
				this.viewOnly = false;
			}
		},
		components: {
			
		},
		// setup() {
		// 	provide("customVal", "我是父组件向子组件传递的值"); 
		// }
	}
</script>

<style scoped>

</style>
